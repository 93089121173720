:root{--background-header:#0D213F;--background-menu:rgba(13,33,63,0.9);--background-content:#FFF;--main-bg-color:#F4F6F8;--background-table-color:#FFF;--width-menu:11rem;--margin-left-menu:11rem;--negative-width-menu:-11em;--margin-left-menu-padding:15rem;--p-border-subdued:#dfe3e8;--font-size:16px;--padding-table:.8rem;--p-border:#c4cdd5;--p-border-radius-base:3px;--avatar-color:#47C1BF;--send-ok-color:#47C1BF;--p-text:#0D213F;--width-menu-closed:0;--color-active:#000;--padding:1rem;--p-border:#c4cdd5;--color-primary:#000;--color-body:#212529;--p-border-radius-wide:3px;--p-text-subdued:rgba(109,113,117,1);--p-surface:rgba(255,255,255,1);--p-card-shadow:0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);--step-success:#00D5B1;--header-height:67px;--padding-lateral:2rem;--width-menu-icon-selected:8.5rem;--padding-menu:0 13px;--padding-menu-icon:.5rem 0 .5rem 13px;--padding-menu-icon-select:.3rem .3rem .3rem 13px;--radius:20px 2px;--width-video-img:45%;--color-primary:#01D765}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");unicode-range:U+0900-097F,U+1CD0-1CF6,U+1CF8-1CF9,U+200C-200D,U+20A8,U+20B9,U+25CC,U+A830-A839,U+A8E0-A8FB}
.font-Roboto{font-family:'Roboto',Courier,monospace}
.font-Poppins{font-family:'Poppins',Courier,monospace}
.font-Oswald{font-family:'Oswald',Courier,monospace}
.font-Lato{font-family:'Lato',Courier,monospace}
.font-Playfair{font-family:'Playfair',Courier,monospace}
.fonts-soscial{padding:.5rem .2rem;border:1px solid #7C868D;border-radius:5px;margin-right:5px;cursor:pointer;font-size:14px}
.fonts-soscial.selected{background:#7C868D;color:#fff}
body{margin:0;overflow:hidden;background-color:var(--main-bg-color);font-family:sans-serif;font-size:var(--font-size);color:var(--p-text)}
.header{overflow:hidden;background-color:var(--background-header);position:fixed;top:0;padding:0 var(--padding-lateral);width:calc(100% - 4rem);display:flex;flex-direction:row;align-items:center;align-content:flex-start;justify-content:space-between;z-index:99;width:calc(100% - 4rem);height:var(--header-height);font-weight:800;color:#fff}
.header .cont{margin:-3rem 0 0}
.header div.logo img{height:43px}
.header div.shop-name{font-size:15px}
select.form-control{padding:.3rem .5rem;border:1px solid #DADADA;border-radius:2px;height: 34px;}
.content-config select.form-control{max-width: 200px;}
.content-reviews:not(.create-flow) .content-config select.form-control{    max-width: calc(100% - 2rem);
  margin: 1rem;
  border-radius: 5px;}
.card-home .high, .card-home .medium, .card-home .low{
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50%;
  margin: 3px 0px 0px -20px}
.card-home .high{background: #68E137;}
.card-home .medium{background: #F3A32A;}
.card-home .low{background: #E4080A;}
.menu .menu-plan{    background: var(--color-primary);
  border-radius: 10px;
  font-size: 8px;
  padding: 2px 8px!important;
  position: absolute;
  right: 0px;
  top: -4px;}
.menu ul li.active > div .menu-plan{display: none;}
.menu{background-color:var(--background-menu);margin:0;padding:0;width:var(--width-menu);position:fixed;height:100%;overflow:auto;top:var(--header-height);left:var(--negative-width-menu);transition:all .5s;padding-bottom:6rem;cursor:pointer}
.open-menu .menu{left:var(--width-menu-closed);z-index:9999999;display:flex;flex-flow:column;justify-content:space-between;height:calc(100vh - 5rem)}
.contact{padding:1rem;color:#fff}
.contact > div{display:flex}
.contact > div img{width:24px;margin-right:1.5rem;margin-top:.5rem}
.contact > div img.whatsapp{width:20px}
.open-menu .Content{margin-left:var(--margin-left-menu);width:calc(100% - var(--margin-left-menu-padding))}
.menu ul li{padding:var(--padding-menu);height:48px}
.menu ul li.sub.submn{padding: 0px 0px 0px 25px;}
.menu ul li > div{position: relative;padding:var(--padding-menu-icon);display:flex;align-content:center;align-items:center;color:#fff}
.menu ul li > div > div{padding-left:13px}
.menu ul li > div img{width:19px}
.menu ul li hr{filter:drop-shadow(0px 4px 4px rgba(0,0,0,0.25)) drop-shadow(0px 4px 4px rgba(0,0,0,0.25));border:1px solid rgba(255,255,255,0.19)}
.menu ul li hr.active{border:1px solid transparent}
.menu ul li.active > div{border-radius:30px;padding:var(--padding-menu-icon-select);margin:.2rem 0;background:var(--background-header);line-height:25px;width:var(--width-menu-icon-selected)}
.content-products{border-radius:0 0 4px 4px;box-shadow:0 1px 4px 0 rgba(0,0,0,.14);border:1px solid #DADADA;padding:5px}
.content-products .cont-stars,.content-products .cont-total{display:flex;flex-direction:column;align-items:center;min-width:9rem}
.content-products .cont-total{min-width:3rem}
.content-products .cont-total-esp{min-width: 8rem;}
.content-products .cont-state{min-width:6rem}
.search-products{padding:15px calc(1rem + 5px);width: calc(100% - 2.75rem);border:1px solid #DADADA;border-radius:4px 4px 0 0;border-bottom:0}
.notifications .search-products{width: calc(100% - 2.75rem);}
.card1{box-shadow: 0px 2px 4px 0px oklch(37.567% 0.022158 281.800147 / .26); }
.search-wa{padding:7.3px;width:12rem;border:1px solid #DADADA;border-radius:4px;margin-right:3px}
.content-review.content-product-item{display:flex;align-items:center;justify-content:space-between;margin-bottom:0;border:none;border-bottom:1px solid #DADADA;border-radius:0;cursor:pointer}
.content-product-item .content-img-prod{display:flex;align-items:center;justify-content:flex-start}
.content-product-item .content-img-prod img{object-fit:cover;width:40px;height:40px;border:1px solid #d3d4dd;border-radius:4px;margin-right:1rem;transition:max-width .6s}
.content-products .cont-stars.inline{flex-direction:row;justify-content:flex-start;width:17rem;margin-top:.5rem}
.content-products .cont-stars.inline [data-star]{line-height:25px;margin-right:1rem}
.content-product-item.show-unic .content-img-prod{align-items:flex-start}
.content-products .content-tags{margin:calc(1rem - 5px)}
.content-products .title-tags{background:#f7f7f8;padding:15px 5px;border-radius:4px;margin-bottom:1rem;font-weight:700;margin-top:2rem}
.content-products .text-tags{padding:5px;line-height:1.6rem}
.content-products .tags{display:flex;gap:8px;flex-wrap:wrap}
.content-products .tags .tag-product{padding:6px 16px;border-radius:4px 4px 0 0;background-color:#f4f4f6;color:#535565;line-height:30px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;border-bottom:2px solid #47C1BF}
.img-b{width:16px;padding:0}
.img-b.bdown{transform:rotate(180deg)}
.pagination{justify-content:center;font-size:2rem;cursor:pointer;display:flex;align-items:center}
.pagination > div:not(.no-Class){background:var(--p-action-secondary,linear-gradient(180deg,#fff,#f9fafb));border:.1rem solid var(--p-border);border-radius:3px;line-height:1;color:var(--p-text);margin:6px 1rem 0}
.pagination div.disabled{background:#f4f6f8;color:#919eab;cursor:default;box-shadow:none}
.pagination select.form-control{padding:9px .5rem;vertical-align: middle;}
.DayPicker_transitionContainer__horizontal{min-height:400px}
.filters-content{padding:0 0 1rem;justify-content:flex-end}
.filters-content ul{list-style:none;margin:0;padding-left:0}
.filters-content ul li{padding:.4rem;color:#666;cursor:pointer}
.filters-content ul li.selected{background:#ebebeb;border-radius:var(--radius);color:#000}
.filter{background:#fff;position:fixed;min-width:25rem;padding:1rem;top:0;z-index:99;right:0;box-shadow:0 31px 41px 0 rgba(32,42,53,.2),0 2px 16px 0 rgba(32,42,54,.08);transition:all .8s;top:var(--header-height);border:1px solid #DADADA;border-radius:var(--radius)}
.no-left,button.btn.no-left{padding-left:0}
.filter .select-text{width:100%}
.filter .close-icon{position:absolute;top:5px;right:0;margin:0 5px;font-size:1.8rem;color:var(--color-body);border:none;background:transparent;cursor:pointer}
.filters-apllied{cursor:pointer;margin-left:1rem;display:flex;align-items:center}
button.btn:focus,button.btn.focus{box-shadow:none}
.btn-filter,.filters-apllied{background:transparent;color:#000;cursor:pointer;border:.740916px solid #C2C2C2;border-radius:2px;padding:5px;font-weight:600;font-size:12px}
.filter .btn-filter{margin-bottom:.5rem;border:none;text-decoration:underline;padding-left:0}
.filter .btn-filter span{padding-left:0}
.btn-filter .img-button{margin-left:1px;height:12px}
.btn-filter span,.filters-apllied span{padding:0 8px}
.Content{padding:0 2rem 0 2rem;margin:0;overflow:auto;overflow:overlay;height:calc(100vh - var(--header-height));position:fixed;background-color:var(--background-content);width:100%;transition:all .5s;padding-bottom:5rem;top:var(--header-height)}
.Content h1{font-size:2rem;line-height:3.2rem}
.select-points{font-size:14px;font-weight:100;text-transform:capitalize;align-items:center;border:1px solid #ccc;padding:0 5px;border-radius:20px 0}
.select-points span{margin-right:1rem}
.select-points > div,.select-points > i{cursor:pointer}
.lds-ripple{display:inline-block;position:relative;width:80px;height:80px}
.lds-ripple div{position:absolute;border:4px solid #fff;opacity:1;border-radius:50%;animation:lds-ripple 1s cubic-bezier(0,0.2,0.8,1) infinite}
.lds-ripple div:nth-child(2){animation-delay:-.5s}
@keyframes lds-ripple {
0%{top:36px;left:36px;width:0;height:0;opacity:0}
4.9%{top:36px;left:36px;width:0;height:0;opacity:0}
5%{top:36px;left:36px;width:0;height:0;opacity:1}
100%{top:0;left:0;width:72px;height:72px;opacity:0}
}
.message-install{margin-bottom:1rem;font-size:18px}
.content-flex{display:flex}
.content-flex.space-between{justify-content:space-between;align-items:flex-end}
.content-flex.space-between-start{justify-content:space-between;align-items:flex-start}
.content-flex.align-flex-start{align-items:flex-start}
.content-flex.flex-start{justify-content:flex-start;align-items:center}
.content-flex.space-between-initial{justify-content:space-between;align-items:initial}
.content-flex.space-between-center{justify-content:space-between;align-items:center}
.content-flex.flex-end{justify-content:flex-end;align-items:flex-end}
.content-flex.flex-center{justify-content:center;align-items:center}
.content-flex.flex-center-left{justify-content:start;align-items:center}
.content-products .cont-stars.titlecont{flex-direction:row}
.content-flex.column{flex-direction:column}
.lh25{line-height:25px}
.g8px{gap:8px}
.image-scroll{overflow:auto}
.image-scroll img{height:100px;border-radius:5px;margin-right:8px;cursor:pointer}
.image-scroll img.disabled,.image-scroll video.disabled,.image-scroll audio.disabled{opacity:.2}
.content-hidden-show{position:relative}
audio{width:220px;height:54px}
.content-hidden-show img{position:absolute;height:auto;margin-top:-117px;background:rgba(255,255,255,.9);padding:5px;border-radius:5px;width:15px;margin-left:-13px;border:1px solid #0D213F;right:-12px}
.content-hidden-show.audio img{margin-top:-74px}
.home .content-flex.space-between-center.buttons.content-image-scroll{flex-direction:column;align-items:flex-start}
.mixed-chart .content-products .cont-stars{width:6rem;margin-left:5px;font-size:14px}
.mixed-chart .content-products .cont-stars [data-star]{font-size:20px}
.mixed-chart .content-products .cont-stars [data-star]:before,.mixed-chart .content-products .cont-stars [data-star]:after{color:#fcb829}
.mixed-chart .content-product-item .content-img-prod img{margin-right:.5rem;width:30px!important;height:30px!important}
.mixed-chart .content-product-item .content-img-prod{width:57%;cursor:initial}
.mixed-chart .content-product-item .content-img-prod > div{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.mixed-chart .content-review.content-product-item{padding:.5rem}
.anaFilter, .content-flow{margin:0 -1rem;width:calc(100% + 3rem)}
.content-reviews .content-flow.content-reviews{border-radius: 5px;margin-top: 1rem;}
.content-hearings{
  margin: -2rem 0px 0px 0px;
    width: calc(100% - 4rem);
    padding: 2rem;
    border-radius: 0px 0px 5px 5px;
    background:#F9FAFB;line-height: 1.4rem;
}
.content-hearings .button.primary{justify-content: center;}
/*USO GENERAL*/
.flex-10{
  display: flex;
  align-items: center;
  gap: 10px;
}

.list-segments{    display: grid;
  grid-template-columns: 3fr 200px 95px 95px;
  align-items: center;    background: #fff;
  padding: 1rem;
  border-radius: 20px 0px;}
.list-segments > div{box-shadow:rgba(26,26,26,0.07) 0 1px 0 0;height: 45px;line-height: 45px;padding: 0px 1rem;overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;}
  .list-segments > div.clickable{cursor: pointer;}

.list-segments > div.active{    background: green;
      color: #fff;}
.list-segments > div.clickable:not(.active):hover{
        background: #ccc;
}  
.list-segments > div svg, .list-segments > div.image img{width: 32px;top: 5px;
  position: absolute;right: 0px;}
.list-segments > div.image{padding-right: 6px;position: relative;}
.list-segments > div.image img{right: 8px;}
.list-segments > div.image > div{position: relative;}
.list-segments > div.head{    color: rgb(55 65 81);    z-index: 1;
  font-weight: bold;
  background: rgb(249 250 251);}
  .list-segments > div.head-action{display: flex;align-items: center;z-index: 1;}
  .list-segments > div.head-action input{max-width: 300px;}
  .list-segments > div.head-action select{padding: 0px;height: 34px;}
  .list-segments::-webkit-scrollbar {
    width: 2px;
    border-radius: 1px;
  }
  
  /* Track */
  .list-segments::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .list-segments::-webkit-scrollbar-thumb {
    background: #CCC; 
    border-radius: 1px;
  }
  
  /* Handle on hover */
  .list-segments::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
.mixed-chart .content-reviews .content-products{box-shadow:none;border:none;padding:0}
.anaFilter .btn-filter.content-flex.flex-center{margin:1rem 1rem 0 0}
.analytics{display:flex;justify-content:flex-start;gap:1rem;flex-direction:row;flex-wrap:wrap;padding:1rem 0 8rem 1rem;background:#F6F6F7;margin:-1rem;width:calc(100% + -1rem);position:absolute}
.analytics .mixed-chart:hover{scale:1.01}
.analytics .mixed-chart{transition:all .2s ease-in-out;padding:1rem;position:relative;background:#fff;border-radius:12px;box-shadow:rgba(26,26,26,0.07) 0 1px 0 0}
.analytics .mixed-chart:before{border-radius:12px;bottom:0;box-shadow:.0625rem 0 0 0 #00000021 inset,-0.0625rem 0 0 0 #00000021 inset,0rem -.0625rem 0 0 #0000002b inset,0rem .0625rem 0 0 #cccccc80 inset;content:' ';left:0;mix-blend-mode:luminosity;pointer-events:none;position:absolute;right:0;top:0;z-index:32}
.analytics .mixed-chart .title-chart{margin-top:0;font-weight:900;font-size:14px;font-family:Helvetica,Arial,sans-serif;color:#373d3f}
.skeleton--graph{border:1px solid;border-color:#bdbdbd;border-radius:2px;padding:20px;background:#fff;height:250px}
.skeleton--graph__shimmer{-webkit-animation-duration:1s;-webkit-animation-fill-mode:forwards;-webkit-animation-iteration-count:infinite;-webkit-animation-name:placeHolderShimmer;-webkit-animation-timing-function:linear;background:#d8d8d8;background-image:linear-gradient(to right,#d8d8d8 0%,#bdbdbd 20%,#d8d8d8 40%,#d8d8d8 100%);background-repeat:no-repeat;background-size:100%;height:100%;position:relative}
.skeleton--graph__shimmer div{background:#fff;left:0;right:0;position:absolute}
.sep{height:100%;width:5px;right:auto}
.top{width:10%;top:0}
.space{width:100%;height:20px}
.row{height:12px}
.skeleton--graph__shimmer > div:nth-child(1){--nth-sep:0}
.skeleton--graph__shimmer > div:nth-child(2){--nth-sep:1}
.skeleton--graph__shimmer > div:nth-child(3){--nth-sep:1}
.skeleton--graph__shimmer > div:nth-child(4){--nth-sep:2}
.skeleton--graph__shimmer > div:nth-child(5){--nth-sep:2}
.skeleton--graph__shimmer > div:nth-child(6){--nth-sep:3}
.skeleton--graph__shimmer > div:nth-child(7){--nth-sep:3}
.skeleton--graph__shimmer > div:nth-child(8){--nth-sep:4}
.skeleton--graph__shimmer > div:nth-child(9){--nth-sep:4}
.skeleton--graph__shimmer > div:nth-child(10){--nth-sep:5}
.skeleton--graph__shimmer > div:nth-child(11){--nth-sep:5}
.skeleton--graph__shimmer > div:nth-child(12){--nth-sep:6}
.skeleton--graph__shimmer > div:nth-child(13){--nth-sep:6}
.skeleton--graph__shimmer > div:nth-child(14){--nth-sep:7}
.skeleton--graph__shimmer > div:nth-child(15){--nth-sep:7}
.skeleton--graph__shimmer > div:nth-child(16){--nth-sep:8}
.skeleton--graph__shimmer > div:nth-child(17){--nth-sep:8}
.skeleton--graph__shimmer > div:nth-child(18){--nth-sep:9}
.skeleton--graph__shimmer > div:nth-child(19){--nth-sep:9}
.skeleton--graph__shimmer > div:nth-child(20){--nth-sep:10}
div.sep{left:calc(var(--nth-sep) * 10%);top:0}
div.top{left:calc(var(--nth-sep) * 10%);top:0}
div.top.top--1{height:10px}
div.top.top--2{height:20px}
div.top.top--3{height:50px}
div.top.top--4{height:30px}
div.top.top--5{height:10px}
div.top.top--6{height:15px}
div.top.top--7{height:20px}
div.top.top--8{height:50px}
div.top.top--9{height:15px}
div.top.top--10{height:0}
div.space.space--1{top:70px;height:30px}
@-webkit-keyframes placeHolderShimmer {
0%{background-position:-468px 0}
100%{background-position:468px 0}
}
@-webkit-keyframes prideShimmer {
from{background-position:top left}
to{background-position:top right}
}
#myImg{border-radius:5px;cursor:pointer;transition:.3s}
.menu ul li > div > div.submenu{width:19px;padding:0;height:19px}
.menu ul li .submenu:before{content:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='28' fill='none'%3E%3Cpath fill='%23B5B5B5' d='M19 14.25h.75v1.5H19v-1.5Zm-8.923-.888.668-.34-.668.34Zm1.311 1.311.34-.668-.34.668ZM10.5 0v10.2H9V0h1.5Zm4.05 14.25H19v1.5h-4.45v-1.5ZM10.5 10.2c0 .853 0 1.447.038 1.91.037.453.107.714.207.912l-1.336.68c-.227-.444-.321-.924-.366-1.47C9 11.694 9 11.027 9 10.2h1.5Zm4.05 5.55c-.828 0-1.494 0-2.032-.043-.546-.045-1.026-.14-1.47-.366l.68-1.336c.198.1.459.17.913.207.462.037 1.056.038 1.909.038v1.5Zm-3.805-2.729c.216.424.56.768.984.984l-.681 1.336a3.75 3.75 0 0 1-1.64-1.638l1.337-.681Z'/%3E%3Cpath stroke='%23B5B5B5' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m17 12 3 3-3 3'/%3E%3C/svg%3E");opacity:0}
.menu ul li:hover .submenu:before,.menu ul li.active .submenu:before{opacity:1}
.menu ul li.sub > div > div{color:#999}
.menu ul li.sub.active > div > div,.menu ul li.sub:hover > div > div{color:#fff}
#myImg:hover{opacity:.7}
.min-input .input-form{width:104px}
.modal{display:none;position:fixed;z-index:1;padding-top:100px;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:#000;background-color:rgba(0,0,0,0.9)}
.modal-content{margin:auto;display:block;max-width:90%;border-radius:16px;height:80%;max-height:85vh}
#caption{margin:auto;display:block;width:80%;max-width:700px;text-align:center;color:#ccc;padding:10px 0;height:250px}
.modal-content,#caption{-webkit-animation-name:zoom;-webkit-animation-duration:.6s;animation-name:zoom;animation-duration:.6s}
@-webkit-keyframes zoom {
from{-webkit-transform:scale(0)}
to{-webkit-transform:scale(1)}
}
@keyframes zoom {
from{transform:scale(0)}
to{transform:scale(1)}
}
.men-exc{display:flex;justify-content:center}
.men-exc div{background:#F2755C;padding:12px 28px;border-radius:20px 0;color:#fff}
.men-exc.suc div{background:var(--color-primary)}
.plan-actual.medium #progressbar>div{background-color:#F1C40F}
.plan-actual.maxReviews #progressbar>div{background-color:#E74C3C}
.message-install .text-label{line-height:2.2rem;display:flex;align-items:center;justify-content:center;max-width:450px;margin:auto;position:relative}
.message-install .text-label span,.message-install .text span{color:var(--color-primary)}
.message-install .title-never{line-height:2rem;font-size:2rem;color:var(--color-primary);margin-bottom:2rem}
.message-install .text{line-height:2rem;margin-bottom:1rem;font-size:22px}
.message-install img{width:2rem;margin:.5rem;position:absolute;left:0}
.message-install .text.tips img{position:static;height:2rem;margin:0 1rem 0 0}
.message-install button{margin-bottom:1rem}
.message-install .text.tips{display:flex;justify-content:flex-start;margin:1rem 0;text-align:left;font-size:16px}
.message-install .text.min{font-size:14px}
.message-install .text.normal{font-size:16px}
.message-install .text.max{font-size:22px}
.donut{width:1.3rem;height:1.3rem;margin:.7rem;border-radius:50%;border:.3rem solid rgba(133,153,183,0.3);border-top-color:#fcfcfc;animation:1.5s spin infinite linear;position:absolute;left:0}
@keyframes spin {
to{transform:rotate(360deg)}
}
.loader-9{margin:2rem auto}
.loader-9 .star1{-webkit-animation:stars-pulse 1s ease-in-out infinite;animation:stars-pulse 1s ease-in-out infinite;left:0}
.loader-9 .star2{-webkit-animation:stars-pulse 1s .2s ease-in-out infinite;animation:stars-pulse 1s .2s ease-in-out infinite;left:25px}
.loader-9 .star3{-webkit-animation:stars-pulse 1s .4s ease-in-out infinite;animation:stars-pulse 1s .4s ease-in-out infinite;left:50px}
.loader-9 .star4{-webkit-animation:stars-pulse 1s .6s ease-in-out infinite;animation:stars-pulse 1s .6s ease-in-out infinite;left:75px}
.loader-9 .star5{-webkit-animation:stars-pulse 1s .8s ease-in-out infinite;animation:stars-pulse 1s .8s ease-in-out infinite;left:100px}
@-webkit-keyframes stars-pulse {
0%,100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}
80%{-webkit-transform:scale(0);transform:scale(0);opacity:0}
}
@keyframes stars-pulse {
0%,100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}
80%{-webkit-transform:scale(0);transform:scale(0);opacity:0}
}
.close{position:absolute;top:10px;right:30px;color:#f1f1f1;font-size:40px;font-weight:700;transition:.3s}
.close:hover,.close:focus{color:#bbb;text-decoration:none;cursor:pointer}
@media only screen and (max-width: 700px) {
.modal-content{max-width:100%}
}
.home.card-home-bottom .title,.home.card-home-bottom .plan-actual{margin-bottom:1rem;font-weight:100;font-size:1rem}
.home.card-home-bottom .plan-actual{    width: 100%;
  margin-left: 0px;
  position: relative;}
.home.card-home-bottom #progressbar{margin-bottom:.5rem}
.home.card-home-bottom .error{display:flex;align-items:center;font-weight:700;    margin-top: 2rem;}
.home.card-home-bottom .error .button{margin-left:1rem}
.card-home{padding:1rem;flex:0 1 29%;background:#0D213F;border-radius:20px 2px;color:#fff}
.card-home .title,.card-home .total{font-weight:500;font-size:14px;line-height:21px}
.card-home .review{font-weight:700;font-size:17px;line-height:26px}
.card-home .percentage{font-weight:700;font-size:38px;line-height:57px}
.card-home .title{margin:0 2rem 1.5rem 0}
.card-home .date{font-weight:500;font-size:11px;line-height:16px;margin:0 0 1.5rem}
.card-home .date.link{color: #fff;
  font-size: 20px;
  text-decoration: none;
  margin-bottom: 21px;
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 4px;
  width: 15px;
  height: 15px;
  text-align: center;}
.card-home img{margin-right:10px}
.card-home-bottom{margin-top:1rem;color:#000}
.card-home-bottom .title{font-weight:800;font-size:12px;line-height:18px;margin:0}
.card-home-bottom .date{font-weight:400;font-size:10px;line-height:15px;margin:0}
.card-home-bottom .percentage{font-weight:700;font-size:28px;line-height:42px;display:flex;align-items:center}
.card-home-bottom .percentage img{margin-left:1rem;cursor:pointer}
.discount-link{margin-top:2rem}
.discount-link a,.discount-link a:visited,.text-utm{display:flex;align-items:center;color:#333;line-height:30px;text-decoration:none}
.card-home.card-home-bottom .discount-link a img{width:.8rem}
.text-utm{font-weight:100;gap: 8px;font-size:15px}
.card-home.card-home-bottom .text-utm img{margin-right: 0px;}
.content-convert{display:flex;align-items:center;background:#fff;border:1px solid #0D213F;color:#0D213F;border-radius:15px;padding:.2rem;justify-content:center;cursor:pointer;position:absolute;margin-top:-16px;margin-left:42px;font-size:11px;min-width:136px}
.content-convert.disabled{opacity:.3}
.image-scroll img.img-convert{width:14px;height:14px;margin-right:0;margin-left:.2rem}
.discount-link > div{margin-bottom:0.5rem}
.card-home.card-home-bottom img{width:25px}
.spacing{height:4rem}
.content-input-color{display:flex;align-items:center;gap:8px;flex-wrap:wrap}
.input-color-prepend{display:flex;width:100%;gap:8px}
.content-input-color .min{width:80px}
.content-table table{width:100%;border-spacing:0;padding:1rem}
.content-table .title,.content-reviews .title,.content-config .title, .title-link{font-weight:700;font-size:22px;line-height:33px;letter-spacing:.03em;margin-bottom:1.5rem;text-transform:uppercase}
.content-reviews .title small{font-size:1rem;letter-spacing:.1px;text-transform:initial}
.content-config .sub-title{font-weight:700;font-size:18px;line-height:16px;letter-spacing:.03em;padding-top:28px;padding-top:28px;align-items:center}
.content-config .sub-title:after{height:5px;margin:0 0 0 1em;content:"";flex:1;background:linear-gradient(to right,#0D213F,#01D765);border-radius:3px}
.number{background:var(--background-header);color:#fff;width:1.5rem;height:1.5rem;border-radius:50%;line-height:1.5rem;display:flex;justify-content:center;margin-right:.8rem}
label.label-file {
  position: relative;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
  margin-top: 1rem;
}
label.label-file.disabled {
  -webkit-pointer-events:none;-moz-pointer-events:none;-ms-pointer-events:none;-o-pointer-events:none;pointer-events:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none;
  opacity: 0.4;
}

.formbold-drop-file {
  display: block;
  font-weight: 600;
  color: #07074d;
  font-size: 20px;
  margin-bottom: 8px;
}

.formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  display: block;
  margin-bottom: 8px;
}
.formbold-browse {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.content-config.conf-whatsapp{
  max-width: 850px;
  border: none;
}
.content-config.conf-whatsapp hr{border: 1px solid rgba(148, 163, 184, 0.2);}
.content-config.conf-whatsapp .text{    line-height: 25px;
  font-size: 1.1rem;
  margin: 1rem 0px;}
.content-config .text{font-size:15px;line-height:18px;letter-spacing:.1em;padding-top:1.2rem;color: #333;
  font-family: system-ui;
  font-weight: 400;}
  .title-spacial{    background: #0D213F;
    color: #fff;
    margin: 2rem -0.5rem;
    padding: 1rem;
    border-radius: 5px;}
.content-flex-imagen{    display: flex;
  align-items: center;
  gap: 8px;}
.content-config .text > div.sptop{margin: 0rem -1rem;}
.content-config .text > div.iti{width: 100%;}
.iti__search-input{padding: 0.8rem 1rem;}
.input-form.iti__tel-input{font-size: 17px;
  padding-top: 19px;}
#myModal2 .input-form.iti__tel-input{padding-top: 15px;}
.content-hearing.content-reviews .title{margin-bottom: 2rem;}
.content-hearing.content-reviews p{line-height: 1.5rem;}
.content-hearing.content-reviews .title .content-flex{gap: 1rem}
.content-hearing.content-reviews{    padding: 1rem 4rem;
  width: calc(100% - 6rem);background: #fff;}
.text-segment{    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
    border-bottom: 1px solid #f5f5f5;}
.content-text-segment{
      margin-bottom: 2rem;
      color: #666;
      box-shadow: 8px 9px 20px 3px rgba(135, 131, 131, 0.65);
      border-radius: 5px;}
.content-segments-ia{
  margin: 0 auto;
  max-width: 1040px;
}
.content-segments-ia textarea{
  box-shadow: inset 0 0 0 0px #fff, inset 0 0 0 calc(1px + 0px) rgb(209 213 219 / 1), 0 0 #0000;
    border-width: 0px;
    border-radius: 0.375rem;
    width: 100%;
    height: 150px;
    line-height: 1.5rem;
    padding: 0.8rem 1rem;
    font-size: 1.2rem;
}
.content-segments-ia p{
  margin-bottom: 2rem;
  line-height: 1.6rem;
}
.content-segment-ia-ok{
  background: rgb(22 163 74 / 0.15);
  padding: 1rem;
  border-radius: 20px 0px;
  color: #666;
  text-align: center;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
.content-segment-ia-ok pre{margin: 0px;white-space: normal;
  line-height: 1.5rem;}
.content-segment-ia-ok div{font-style: italic;font-weight: 700;}
.content-exampleia{
  display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 3rem;
}
.content-exampleia > div {
  box-shadow: 0rem -.0625rem 0rem 0rem #b5b5b5 inset, 0rem 0rem 0rem .0625rem rgba(0, 0, 0, .1) inset, 0rem .03125rem 0rem .09375rem #FFF inset;
  padding: 6px 12px;
  border-radius: 10px;
  cursor: pointer;
}
#form.is-dragover {
  background-color: grey;
}
.linkstemplate{    display: flex;
  flex-direction: column;
  gap: 1rem;
}
#form{    display: flex;
  flex-direction: column;
  gap: 1rem;}
  .linkstemplate  img{
    max-width: 500px;
    width: 80%;
    border-radius: 5px;
  }
table{border-collapse: initial;}
*, ::after, ::before, ::backdrop, ::file-selector-button {
    box-sizing: initial;
    margin: 0;
    padding: 0;
    border: 0 solid;
}
.linkstemplate a,.linkstemplate a:hover,.linkstemplate a:visited{line-height: 1rem;color: #333;}
.content-config.sptop{margin-bottom: 1rem;}
.content-flex-imagen > img {width: 24px;}
.content-config .text > div{margin-top:.4rem;position: relative;}
.content-table table thead th{text-align:left;font-weight:700;font-size:12px;line-height:16px;letter-spacing:.02em;text-transform:uppercase;padding-bottom:1.8rem}
.content-table table tbody tr td{font-weight:400;font-size:11px;line-height:16px;letter-spacing:.1em;padding:1rem 0;border-bottom:.555687px solid #C2C2C2}
.content-flex-two > div{flex:0 1 45%}
.content-review,.content-config{border:1px solid #DADADA;border-radius:5px;padding:1rem;margin-bottom:1rem}
.content-config.nb{border: none;padding: 0px;}
.content-review-item, .content-table table, .card-home-bottom{background:#F3F3F3;border-radius:20px 2px;border:1px solid #F3F3F3;transition:all .5s ease-out}
.content-review .flex1{border-right:1px solid #DADADA;margin-right:1rem;flex:0 1 25%}
.content-review .flex1 img{width:90px}
.home .content-review .flex1{flex:0 1 40%}
.home .content-review .flex2{flex:0 1 55%}
.home .content-reviews{padding:0 1rem;border:none}
.content-review .flex2{flex-grow:2;justify-content:space-between;flex-direction:column;display:flex;flex:0 1 75%}
.update-image{font-size:10px;position:absolute;margin-left:-90px;width:85px;padding:15px 5px;color:#333;text-align:center}
.flex1 .column{padding:0 1rem;align-items:flex-start}
.content-review .name{font-weight:400;font-size:10px;line-height:15px;letter-spacing:.1em}
.content-review .order,.content-review .order a,.content-review .order a:visited{font-weight:600;font-size:12px;line-height:18px;letter-spacing:.06em;font-size:var(--font-size);color:var(--p-text);text-decoration:none}
.content-review .date{font-weight:400;font-size:12px;line-height:16px;color:#7E7E7E}
.content-review .comment{font-style:normal;font-size:11px;line-height:16px;letter-spacing:.1em}
.charge-amount{font-size:14px}
.content-review .customer-name{font-weight:700;font-style:normal;font-size:12px;line-height:18px;letter-spacing:.1em;margin-bottom:1.5rem;display:flex;align-content:center;align-items:center}
.content-review .customer-name a{line-height:8px}
.list-messages div.cont-wa-item,.crate-wa .wp{min-width:350px;width:350px;margin-right: 1rem;}
.list-messages > div img,.crate-wa .wp img, .list-messages > div video,.crate-wa .wp video{width:100%;border-radius:5px}
.wp .headerText{font-weight:700}
.wp .name{position:absolute;margin-top:-25px;width:100%;text-align:center;color:#999}
.wp .img-black{width:350px;border-radius:5px;background-color:#999;height:300px}
.wp .img-black:empty{display: none;}
.content-config .text h2{margin:0;padding-top:0;font-size:0.9rem;color:#000;font-weight: 400;}
.content-config .text small{color:#333;line-height:1.3rem;font-size:.7rem;display:flex;flex-direction:column;gap:6px;padding-top:6px;height:0;opacity:0;transition:all 1s linear;overflow:hidden;    position: absolute;
  background: #fff;
  z-index: 2;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 5px;}
.content-config .text small ul{list-style:none;padding-left:0}
.content-config .text small img{max-width:100%;border-radius:3px}
.content-config input:focus ~ small,.content-config textarea:focus ~ small{height:auto;opacity:1}
.cont-wp,.list-messages{position:relative;background:#efe7dd url(/img/wpb.jpg) repeat;z-index:0;width:370px;padding:20px;border-radius:5px}
div.list-messages{display:flex;width:calc(100% - 40px);margin-top:1rem;overflow:auto}
.tabs-sid ~ .list-messages{border-radius: 0px 0px 5px 5px;    min-height: 300px;}
.wa-add{position:absolute;margin-top:-35px;right:0;display:flex;gap:0px;align-items:center}
.wa-add button:nth-child(1),.wa-add button:nth-child(2),.wa-add button:nth-child(3){background:#666;border:#666;font-weight:100;font-family:initial}
.wa-add button:nth-child(1):hover,.wa-add button:nth-child(2):hover,.wa-add button:nth-child(3):hover{background:#333;border:#333}
.wa-add button:nth-child(1){border-radius:30px 0 0 30px;font-weight:700}
.wa-add button:nth-child(2){border-radius:0;font-style:italic}
.wa-add button:nth-child(3){border-radius:0 30px 30px 0;text-decoration:line-through; margin-right: 5px;}
.wa-add button:nth-child(4){margin-right: 5px;}
.list-buttons{display: flex;
      max-width: 100%;
      flex-wrap: wrap;
      gap: 8px;
      margin: 8px 0px;}
.list-messages > div .wp,.crate-wa .wp{font-size:.9rem;background-color:#fff;border-radius:0 5px 5px 5px;box-shadow:0 0 5px #170000 0 1px 2px #000;box-shadow:0 0 5px rgba(23,24,24,.05),0 1px 2px rgba(0,0,0,.15);box-shadow:0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);box-shadow:var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15));padding:.5rem;line-height:1rem;white-space:pre-wrap;gap:8px;display:flex;flex-direction:column;cursor:pointer;font-family:Helvetica;color:#000;position:relative}
.crate-wa .wp{margin-right:0}
.wp:after{border-width:0 10px 10px 0;border-color:transparent #fff transparent transparent;top:0;left:-10px}
.content-plan > div div.limited{display:flex;justify-content:center;gap:8px;margin-bottom:0;align-items:center}
.content-plan > div div.limited select{padding:3px;border-radius:5px}
.wp:after{position:absolute;content:"";width:0;height:0;border-style:solid}
.wp.active:after{border-color:transparent #9DEAA6 transparent transparent}
.background-style{    background-image: linear-gradient(45deg, #ebf5ff, transparent), url('/img/hero-pattern.svg');
  padding: 1rem 2rem;
  border-radius: 5px;}
.content-reviews.crate-wa .content-tabs ul{margin-bottom: 5px;}
table.cvsformat{text-align: left;
  background: #111;
  color: #fff;
  padding: 13px;
  border-radius: 5px;
  font-family: system-ui;}
.list-messages > div .wp.active{background:#9DEAA6}
.list-messages > div .footer,.crate-wa .wp .footer{font-size:.7rem;color:#999}
.list-messages > div .button-wp,.crate-wa .wp .button-wp{box-shadow:var(--p-card-shadow,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15));border-radius:5px;color:#3E749C;display:flex;align-items:center;justify-content:center}
.list-messages > div .button-wp img,.crate-wa .wp .button-wp img{width:24px; padding: 5px;}
.crate-wa{padding:8px 0;border-radius:5px}
.crate-wa > div.content-flex.space-between{gap:1.5rem;align-items:stretch}
.crate-wa > div > .content-config{    overflow: hidden;width: calc(100% - 460px);flex:2;background:#fff;box-shadow:0 0 13px 0 rgba(66,68,90,0.61);margin-bottom:0}
.crate-wa > div > .cont-wp{box-shadow:0 0 13px 0 rgba(66,68,90,0.61)}
.details-wa{display:flex;gap:16px;align-items:flex-start}
.details-wa .det, .datail-campaing{display:flex;flex-direction:column;gap:16px;background-color:var(--main-bg-color);font-family:sans-serif;border-radius:20px 0;padding:2rem;margin-bottom:2rem}
.datail-campaing{margin: 1rem;}
.datail-campaing hr{border: 1px solid #fff;
  width: 100%;
  background: #fff;}
.datail-campaing-link{background-color:var(--main-bg-color);border-radius:20px 0;padding:2rem;display: flex;align-items: center;gap: 1rem;}
p.text-small{margin: 0.5rem 0;color: #555;font-size: 0.8rem;}
.text-help{background-color:var(--main-bg-color);border-radius:20px 0;padding:16px;font-size:13px;line-height:20px;font-family:sans-serif;position:relative}
.text-help:after{content:url('/img/info.png');position:absolute;top:-15px;right:-15px}
.details-wa .det b,.dateInput .input-form{font-weight:800;font-size:16px;line-height:18px}
.dateInput{display:flex;align-items:center;pointer-events:none}
.preview .crate-wa{padding:0}
.dateInput .input-form{border:none;background:transparent;width:210px;font-family:sans-serif;padding:0 4px;color:var(--p-text);pointer-events:none}
.space-top{margin-top:43px}
.button{font-weight:600;font-size:12px;letter-spacing:.05em;white-space:nowrap;display:flex;align-items:center;cursor:pointer;border-radius:5px;padding:8px 15px}
.img-button{margin-left:.5rem;}
.bt-img-16 .img-button{width:16px;}
.bt-img-14 .img-button{width:14px;}
.content-products .text-prom, .content-products .button_campaign{margin: 1rem;}
.buttons{margin-top:1rem}
.button:hover{text-decoration: underline;}
.buttons .button ~ .button{margin-left:1rem}
.button.second{background:#0D213F;border:1px solid #0D213F;color:#fff}
.button.third{background:var(--background-menu);border:1px solid var(--background-menu);color:#fff}
.button.quarter{background:#1F618D;border:1px solid #1F618D;color:#fff}
.button.link{background:transparent;border:none;color:#333;text-decoration:underline}
.button.primary{background:var(--color-primary);color:#FFF;border:1px solid var(--color-primary)}
.img-button-inic{width:16px;margin-right:0}
.cont-wa-item{position: relative;}
.delete, .edit{position: absolute;cursor:pointer;padding: 4px;background: #333;border-radius: 5px;width: 16px;height: 16px;z-index: 2;color: #fff;display: flex;align-items: center;justify-content: center;right: 0px;opacity: 0.4;font-size: 14px;}
.delete:hover, .edit:hover{opacity: 1;}
.edit{right: 28px;}
output{position:absolute;margin-top:16px;margin-left:10px;font-weight:700}
.link-product{text-decoration:none}
.link-product div{padding:16px 0;border-radius:4px;color:#111;margin-top:1rem;display:flex;align-items:center}
.link-ext-product{position:absolute;/*margin-top:-2rem;margin-left:-1rem*/}
.content-product-item .link-product img{width:16px;height:16px;border:none;margin-right:6px}
.success,.content-config .text.success{background:var(--color-primary);font-size:1rem;color:#fff;padding:1rem;margin-top:1rem;border-radius:var(--radius)}
.button.primary:disabled,.button.third:disabled{background:#999;cursor:initial;border-color: transparent;}
audio::-webkit-media-controls-panel{background-color:#0D213F;border-radius:30px}
audio::-webkit-media-controls-play-button,audio::-webkit-media-controls-panel{background-color:#fff;color:#fff}
input[type=range]{-webkit-appearance:none;margin:10px 0;width:85%}
input[type=range]:focus{outline:none}
input[type=range]::-webkit-slider-runnable-track{width:100%;height:12.8px;cursor:pointer;box-shadow:0 0 0 #000000,0 0 0 #0d0d0d;background:var(--background-header);border-radius:25px;border:0 solid #000101}
input[type=range]::-webkit-slider-thumb{box-shadow:0 0 0 #000000,0 0 0 #0d0d0d;border:0 solid #000;height:20px;width:39px;border-radius:7px;background:var(--color-primary);cursor:pointer;-webkit-appearance:none;margin-top:-3.6px}
input[type=range]:focus::-webkit-slider-runnable-track{background:var(--background-header)}
input[type=range]::-moz-range-track{width:100%;height:12.8px;cursor:pointer;animate:.2s;box-shadow:0 0 0 #000000,0 0 0 #0d0d0d;background:var(--background-header);border-radius:25px;border:0 solid #000101}
input[type=range]::-moz-range-thumb{box-shadow:0 0 0 #000000,0 0 0 #0d0d0d;border:0 solid #000;height:20px;width:39px;border-radius:7px;background:var(--color-primary);cursor:pointer}
input[type=range]::-ms-track{width:100%;height:12.8px;cursor:pointer;animate:.2s;background:transparent;border-color:transparent;border-width:39px 0;color:transparent}
input[type=range]::-ms-fill-lower{background:var(--background-header);border:0 solid #000101;border-radius:50px;box-shadow:0 0 0 #000000,0 0 0 #0d0d0d}
input[type=range]::-ms-fill-upper{background:var(--background-header);border:0 solid #000101;border-radius:50px;box-shadow:0 0 0 #000000,0 0 0 #0d0d0d}
input[type=range]::-ms-thumb{box-shadow:0 0 0 #000000,0 0 0 #0d0d0d;border:0 solid #000;height:20px;width:39px;border-radius:7px;background:var(--color-primary);cursor:pointer}
input[type=range]:focus::-ms-fill-lower{background:var(--background-header)}
input[type=range]:focus::-ms-fill-upper{background:var(--background-header)}
.item-review{margin:1rem .8rem 1rem 0}
.hidden{overflow:hidden}
.points{margin:8px 0}
.points div{background-image:url(/img/nopoint.png);width:14px;height:14px;background-size:14px;background-repeat:no-repeat;margin-right:5px}
.points div.active{background-image:url(/img/point.png)}
.content-tabs ul{list-style-type:none;margin:0;padding:0;display:flex;margin-bottom:2rem;border-bottom:1px solid #DADADA}
.content-tabs ul li{font-weight:400;font-size:12.9402px;line-height:19px;text-align:center;height:50px;line-height:50px;border:1px solid transparent;border-bottom:2px solid transparent;cursor:pointer;transition:background-color .5s ease;background-color:#fff;padding:0 3rem;white-space:nowrap}
.content-tabs ul li.active{display: flex;align-items: center;gap: 8px;border:.740916px solid #C2C2C2;border-radius:7px 7px 0 0;font-weight:700;border-bottom:2px solid var(--color-primary);position:relative;top:1px}
.content-tabs ul li select {padding: 5px;
  border-radius: 5px;
  border-color: #ddd;
  color: #555;}
.tabs-sid.content-tabs ul{margin-bottom: -1rem;margin-top: 2rem;}
.ellipsis{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.w8R{width:8rem}
.w20{width:20%}
.star-select-config .points{margin:0;border:none}
.star-select-config .select-points span{margin-right:0}
.content-flex.disabled{opacity:.2}
.update-plan-name{background:var(--color-primary);border-radius:var(--radius);padding:5px 13px;color:#fff;display:initial}
.form-check-conteiner{display:block;position:relative;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;margin:0;border:1px solid #D3D3D3;border-radius:var(--radius);padding:1rem 1.5rem;max-width:38rem;margin:1rem 1rem 1rem 0}
.form-check-conteiner.form-check-config{border:none;padding:0;margin:0}
.form-check-conteiner.form-check-config .form-check-span{top:0;left:0}
.form-check-conteiner.active{border:2px solid #000}
.form-check-conteiner .name{padding-left:2rem}
.form-check-conteiner .title-check{font-weight:700;font-size:12px;line-height:18px;letter-spacing:.1em;padding:1rem 0}
.form-check-conteiner .text-check{font-weight:400;font-size:12px;line-height:18px;letter-spacing:.1em}
.form-check-conteiner input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}
.form-check-span{position:absolute;top:1rem;left:1.5rem;height:18px;width:18px;background-color:#eee;border:1px solid #aaa;border-radius:3px;margin-left:2px}
.form-check-conteiner:hover input ~ .form-check-span{background-color:#ccc}
.form-check-conteiner input:checked ~ .form-check-span{background-color:var(--color-primary)}
.form-check-conteiner input:disabled ~ .form-check-span{background-color:#aaa;border-color:#333;cursor:not-allowed}
.form-check-span:after{content:"";position:absolute;display:none}
.form-check-conteiner input:checked ~ .form-check-span:after{display:block}
.form-check-conteiner .form-check-span:after{left:5px;top:2px;width:6px;height:10px;border:solid #fff;border-width:0 2px 2px 0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg)}
.content-plan{overflow:auto;padding:2rem 8px}
.content-plan > div{box-shadow:0 0 10px #999;border-radius:6px;padding:1rem;margin-right:1rem;text-align:center;width:16rem;min-width:16rem;display:flex;flex-direction:column}
.content-plan > div:hover{z-index:2;transform:scale(1.03);box-shadow:0 2px 15px 0 #000}
.content-plan > div div{margin-bottom:1.5rem}
.content-plan .name{font-size:1.5rem;margin-bottom:2rem}
.content-plan .cost{font-size:1.5rem;font-weight:700}
.content-plan .cost.offer{margin-bottom:5px;margin-top:-32px}
.content-plan .cost.offer del{font-size:16px;opacity:.5}
.content-plan .cost.offer small{color:#d63163;background-color:#ffe8ef;padding:4px 12px;border-radius:20px;font-size:14px;opacity:1}
.content-plan .button{display:inline;margin-bottom:1rem}
.content-plan .active .button{opacity:.7}
.content-plan .active:hover .button{opacity:1}
.content-plan .list-plan{text-align:left;list-style:none;margin-bottom:2rem;padding-left:0}
.content-plan .list-plan li{line-height:2rem; font-size: 15px;}
.content-plan .list-plan li:before{width:12px;content:url(https://assets.hostinger.com/images/index-new/check-82319b207b.svg);display:inline-block;margin-right:5px}
.content-plan .actual{position:static;margin-top:-1.8rem;background:var(--color-primary);color:#FFF;width:16rem;padding:.2rem 1rem;margin-left:-1rem;border-radius:var(--radius);opacity:0}
.content-plan .active .actual{opacity:1}
.content-plan > div.active{box-shadow:0 0 16px #000}
.content-plan > div.none{display:none}
.step{display:flex;max-width:20rem;justify-content:space-between;width:100%;margin:2rem auto}
.step > div{background:#0C213F;border-radius:50%;width:3rem;height:3rem;color:#fff;line-height:3rem;font-size:1.6rem}
.step .line{flex-grow:2;width:auto;height:5px;border-radius:0;margin-top:22px;background:#00D765}
.step .active{background:#00D765}
[data-star]{text-align:left;font-style:normal;display:inline-block;position:relative;unicode-bidi:bidi-override;font-size:25px}
[data-star]::before{display:block;content:'☆☆☆☆☆';color:#0D213F}
[data-star]::after{white-space:nowrap;position:absolute;top:0;left:0;content:'★★★★★';width:0;color:var(--color-primary);overflow:hidden;height:100%}
.star-list{padding:1rem;max-width:40rem}
.button_campaign{display:flex;gap:0px;margin-bottom:2rem;margin-top: 2rem;gap: 0.8rem;}
.button_campaign.t3 button:nth-child(1){border-radius: 5px 0px 0px 5px;}
.button_campaign.t3 button:nth-child(2){border-radius: 0px;}
.button_campaign.t3 button:nth-child(3){border-radius: 0px 5px 5px 0px;}
.button_campaign.t2 button:nth-child(1){border-radius: 5px 0px 0px 5px;}
.button_campaign.t2 button:nth-child(2){border-radius: 0px 5px 5px 0px;}
.sub_title{margin: 2rem 0rem 1.5rem 0rem;
  font-size: 1.5rem;
  font-family: arial;}
#myModal2 #caption{background:#f9f9f9;padding:2rem 2rem 4rem;border-radius:var(--radius);color:#222;text-align:left}
#myModal2 #caption .text{display:flex;flex-direction:column;gap:8px;margin-bottom:20px}
.cont-date{display:flex;flex-direction:column;align-items:flex-end}
/*.content-product-item.DRAFT{background-image: linear-gradient(to bottom,#fff,rgba(255, 195, 0, 0.1))}
.content-product-item.PROCESSED{background-image: linear-gradient(to bottom,#fff, rgba(171, 235, 198, 0.2))}
.content-product-item.ERROR{background-image: linear-gradient(to bottom,#fff,rgba(255, 87, 51, 0.1))}
.content-product-item.PENDING{background-image: linear-gradient(to bottom,#fff,rgba(220, 118, 51, 0.1))}*/
.content-review.content-product-item.cont-camp-head{background-color: rgb(243 244 246);    color: rgb(55 65 81);}
.cont-date > div{font-size:13px;color:var(--background-header)}
.cont-date > div:first-of-type{font-weight: bold;}
.text-campaing{display:flex;flex-direction:column;gap:8px;padding:0 0 1rem 1rem}
.ng-star-content{display:flex;justify-content:flex-start;align-items:center;margin-bottom:.2rem;padding-bottom:5px}
.ng-star{color:#fcb829;font-size:1.2rem;line-height:1.5rem}
.ng-label{display:flex;align-items:center;gap:8px;font-weight:700;line-height:1.5rem}
.ng-ratingBreakdownGraph{color:#676986;font-size:13px;min-width:38px;text-align:right}
.c-ratingBreakdownGraph-row-bar{width:20rem;position:relative;margin:0 1rem}
.ng-bar-background,.ng-row-bar-foreground{width:100%;background-color:#f7f7f8;height:16px;border-radius:4px;position:absolute;top:-8px}
.ng-row-bar-foreground{background-color:#d3d4dd}
.points-icon [data-star]::after{color:#0D213F}
.revieStar span{padding-left:10px;line-height:48px}
.step-items{border: 1px solid rgb(228, 228, 231);border-radius: 5px;margin-bottom: 1rem;}
.step-item{opacity: 0.5;border-bottom: 1px solid rgb(228, 228, 231);padding: 1rem;display: flex;justify-content: space-between;align-items: center;}
.step-item.loader {opacity: 0.8;}
.step-item.ckeck {opacity: 1;font-weight: bold;}
.step-item.ckeck > div.icon{color: var(--color-primary);font-size: 14px;}
.error-mens{display: block;margin-top: 5px;}
.step-item.error-message{opacity: 1;}
.step-item > .lds-ellipsis{margin-right: 1rem;}
[data-star^="0.1"]::after,[data-star^=".1"]::after{width:2%}
[data-star^="0.2"]::after,[data-star^=".2"]::after{width:4%}
[data-star^="0.3"]::after,[data-star^=".3"]::after{width:6%}
[data-star^="0.4"]::after,[data-star^=".4"]::after{width:8%}
[data-star^="0.5"]::after,[data-star^=".5"]::after{width:10%}
[data-star^="0.6"]::after,[data-star^=".6"]::after{width:12%}
[data-star^="0.7"]::after,[data-star^=".7"]::after{width:14%}
[data-star^="0.8"]::after,[data-star^=".8"]::after{width:16%}
[data-star^="0.9"]::after,[data-star^=".9"]::after{width:18%}
[data-star^="1"]::after{width:20%}
[data-star^="1.1"]::after{width:22%}
[data-star^="1.2"]::after{width:24%}
[data-star^="1.3"]::after{width:26%}
[data-star^="1.4"]::after{width:28%}
[data-star^="1.5"]::after{width:30%}
[data-star^="1.6"]::after{width:32%}
[data-star^="1.7"]::after{width:34%}
[data-star^="1.8"]::after{width:36%}
[data-star^="1.9"]::after{width:38%}
[data-star^="2"]::after{width:40%}
[data-star^="2.1"]::after{width:42%}
[data-star^="2.2"]::after{width:44%}
[data-star^="2.3"]::after{width:46%}
[data-star^="2.4"]::after{width:48%}
[data-star^="2.5"]::after{width:50%}
[data-star^="2.6"]::after{width:52%}
[data-star^="2.7"]::after{width:54%}
[data-star^="2.8"]::after{width:56%}
[data-star^="2.9"]::after{width:58%}
[data-star^="3"]::after{width:60%}
[data-star^="3.1"]::after{width:62%}
[data-star^="3.2"]::after{width:64%}
[data-star^="3.3"]::after{width:66%}
[data-star^="3.4"]::after{width:68%}
[data-star^="3.5"]::after{width:70%}
[data-star^="3.6"]::after{width:72%}
[data-star^="3.7"]::after{width:74%}
[data-star^="3.8"]::after{width:76%}
[data-star^="3.9"]::after{width:78%}
[data-star^="4"]::after{width:80%}
[data-star^="4.1"]::after{width:82%}
[data-star^="4.2"]::after{width:84%}
[data-star^="4.3"]::after{width:86%}
[data-star^="4.4"]::after{width:88%}
[data-star^="4.5"]::after{width:90%}
[data-star^="4.6"]::after{width:92%}
[data-star^="4.7"]::after{width:94%}
[data-star^="4.8"]::after{width:96%}
[data-star^="4.9"]::after{width:98%}
[data-star^="5"]::after{width:100%}
.revieStar > div{display:flex;align-content:center}
.revieStar > div > small{padding-left:5px}
.btton-left .button{margin-left:1rem}
.image-conf{flex:1 0 var(--width-video-img);max-width:var(--width-video-img);margin-top:1rem}
.image-conf img{width:100%}
.content-plan .actual.proximo{position:relative;margin-top:8px;width:14rem;margin-left:0;background:red;margin-bottom:0}
progress.Polaris-ProgressBar{accent-color:#87cefa;width:15rem}
progress#progressbar{-webkit-appearance:none;background-color:orange}
progress#progressbar::-webkit-progress-bar{background-color:orange}
progress#progressbar::-webkit-progress-value{background-color:var(--color-primary)}
#progressbar{background-color:var(--background-header);border-radius:30px;padding:3px;margin-bottom:0;height:20px}
#progressbar>div{background-color:var(--color-primary);width:40%;height:20px;border-radius:30px;min-width:15%;color:#fff;font-size:12px;line-height:20px;text-align:right}
.content-plan > div div.plan-actual,.content-plan > div div.plan-actual > div{margin-bottom:0}
.content-plan > div div.plan-actual small{font-size:14px}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form input.se-input-form._se_image_file{display:none}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-btn.se-dialog-files-edge-button.se-file-remove{display:none}
.content-widget{background-color:var(--background-table-color)}
.alingLeft{float:left}
.alingRight{float:right}
.border-0{border:none}
.load-img-carriers{width:4rem}
.dashboard-video{height:400px;position:relative;display:flex}
.dashboard-video iframe{width:700px}
.dashboard-video div{margin-left:2%}


.tabs p {
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
  }
  .tabs p small{
    font-size: 14px;
    color: #666;
  }
  .tabs {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    background: #e5e5e5;
    box-shadow: 0 8px 16px 8px rgba(0,0,0,0.1);
    margin-top: 1rem;
    border-radius: 5px;
  }
  .tabs .input {
    position: absolute;
    opacity: 0;
  }
  .tabs .label {
    width: 100%;
    padding: 20px 30px;
    background: #e5e5e5;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    color: #7f7f7f;
    transition: background 0.1s, color 0.1s;
    margin-bottom: 0px;
    border-radius: 5px 5px 0px 0px;
  }
  .tabs .label:hover {
    background: #d8d8d8;
  }
  .tabs .label:active {
    background: #ccc;
  }
  .tabs .input:focus + .label {
    z-index: 1;
  }
  .tabs .input:checked + .label {
    background: #fff;
    color: #000;
  }
  @media (min-width: 600px) {
    .tabs .label {
      width: auto;
    }
  }
  .tabs .panel {
    display: none;
    padding: 20px 30px 30px;
    background: #fff;
    width: 100%;
  }
  @media (min-width: 600px) {
    .tabs  .panel {
      order: 99;
    }
  }
  .tabs  .input:checked + .label + .panel {
    display: block;
  }

.content-flow{
  padding: 1rem;
  width: 100%;
}
.select-flow{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  padding: 1rem 0rem;
}

.select-flow > article{
  /*background-image: linear-gradient(45deg, #ebf5ff, transparent), url('/img/hero-pattern.svg');*/
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;

  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0,0,0,.05);
  border: 1px solid rgb(229 231 235);
  border-radius: 20px 0px;
  font-family: system-ui, -apple-system;
}
.click{cursor: pointer;}
.click.disabled{background: #efefef;cursor: not-allowed;}
.select-flow img, .card1 img{
  height: 32px;
}
.card1 .content-flex.space-between{    margin-top: 26px;}
.select-flow > article h2{    margin: 0px;
  font-size: 1.3rem;}

.select-flow > article.ia h2{
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
.select-flow > article p {    margin: 0px;
    font-size: 1rem;
      line-height: 1.4rem;}
    .select-flow > article:hover{background-image: linear-gradient(45deg, rgb(195 221 274), transparent), url('/img/hero-pattern.svg');}
  .content-select-tabs{
    background-color: white;
    border: thin solid #D1D1D2;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5rem;
    color:#333;
    position: relative;
}
.text-query{    font-size: 1.3rem;
  font-weight: bold;
  font-style: italic;} 
  .create-flow{margin-top: 1rem;}
.content-select-tabs .select{
  background-image:
    linear-gradient(45deg, transparent 50%, #D1D1D2 50%),
    linear-gradient(135deg, #D1D1D2 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
  calc(100% - 20px) calc(1em + -6px),
  calc(100% - 15px) calc(1em + -6px),
  calc(100% - 2.5em) 0.1em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  cursor: pointer;
}

.content-select-tabs .select.open {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) calc(1em + -6px),
    calc(100% - 20px) calc(1em + -6px),
    calc(100% - 2.5em) 0.1em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}
.select-options{
  position: absolute;
  background: #fff;
  border: thin solid #D1D1D2;
  left: -1px;
  width: calc(100%);
  top: 55px;
  border-radius: 4px;
  cursor: pointer;
  max-height: 170px;
  overflow: auto;
}

.select-options div.cont-show{
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  padding: 0.5rem;
}
.select-options div.cont-show.active{
  background: #EAFAF1;
  font-weight: bold;
}
.select-options div.cont-show:hover{
  background: #f5f5f5
}
.select-options div.cont-show:last-of-type{
  border-bottom: none;
}
.select-options div.cont-show > p{
  margin: 0px;
  font-size: 13px;
  line-height: 1.2rem;
  color: #777;
  font-weight: 100; 
}
.content-config .select-options div.cont-show > p:not(.description) > small{
  height: auto;
  overflow: visible;
  opacity: 1;
  color: #555;
  font-style: italic;
  line-height: 1rem;
}
.content-config .select-options div.cont-show > p.description > small{
  opacity: 1;
  color: #555;
  font-style: italic;
  line-height: 1rem;
  position: relative;
  border: none;
  padding: 0px;
  height: 1rem;
  background: transparent;
}
.select-options div.cont-show > label{
  color: #333;
}
@media (max-width:1100px) {
.dashboard-video{flex-wrap:wrap}
}
.content-flex-two p{flex:1 0 49%}
.content-flex-two p:first-of-type{margin-right:2%}
.content-popup{text-align:center;margin:2rem}
.content-popup div{margin:2rem 0}
.presentacion{background:#fff;height:calc(100vh - 11rem);margin-top:var(--header-height);text-align:center;padding:5rem;overflow:auto}
.presentacion img.logoBlack{width:230px;height:43px}
.presentacion .frame-1{font-family:'Avenir';font-style:normal;font-weight:800;font-size:20.8468px;line-height:15px;text-align:center;text-transform:lowercase;color:#0D213F}
.presentacion .frame-2{font-family:'Avenir';font-style:normal;font-weight:500;font-size:16px;line-height:29px;text-align:center;color:#0D213F;margin:2rem auto}
.presentacion .frame-3{font-family:'Avenir';font-style:normal;font-weight:500;font-size:18px;line-height:29px;text-align:center;color:#0D213F;margin:2rem auto}
.presentacion .frame-4{font-family:'Avenir';font-style:normal;font-weight:800;font-size:25.85px;line-height:27px;text-align:center;text-transform:lowercase;color:#0D213F}
.presentacion .frame-5{font-family:'Avenir';font-style:normal;font-weight:500;font-size:15px;line-height:24px;text-align:center;color:#0D213F}
.radios >div >div{display:flex;justify-content:center;margin:2rem auto;flex-flow:wrap}
.radios >div >div .form-check{display:flex;align-content:center;align-items:center;border:none;padding:0;margin:0 2rem 2rem 0;display:flex;white-space:nowrap;width:auto}
.radios >div >div .form-check .form-check-span{position:initial;margin-right:.4rem}
.radios >div >div .form-check-conteiner .form-check-span:after{left:8px}
.radios >div >div .form-check-conteiner input:checked ~ .form-check-span{background-color:#00D765;border:1px solid #00D765}
.recomend{position:absolute;margin-top:3rem;font-size:10px;background:#BFF5D8;border-radius:var(--radius);padding:2px 5px;font-weight:700}
input.form-check-input[type='checkbox']{accent-color:hsl(148,100%,42%);width:1.3rem;height:1.3rem;margin-right:.4rem}
.presentacion .gif{max-height:calc(100vh - 26rem);min-height:15rem;height:auto}
.presentacion button.btn{background:#0D213F;border-radius:30px;padding:6px 16px;color:#fff;font-family:'Avenir';font-style:normal;font-weight:800;font-size:16px;letter-spacing:.01em;text-transform:lowercase;margin-top:1rem;cursor:pointer}
.login{background-image:url(/img/pantalla-triciclo-go.png);background-size:cover;background-attachment:fixed;background-repeat:no-repeat;width:100%;min-height:100vh;padding:2rem;cursor:pointer}
.login button.btn{font-size:2rem}
.login img{width:13rem;position:absolute;bottom:5px;left:1rem;animation:fadeInBack 3s ease-in 7s backwards}
.login h2{font-size:5rem;width:100%;color:transparent;animation:blurFadeInOut 3s ease-in backwards}
.login .success{background:rgba(255,255,255,0.8);border-radius:5px;padding:2rem;text-align:center;margin:1rem auto;max-width:62rem}
.login .success .title{color:green;font-size:2.5rem}
.login .success h3{margin-bottom:3rem}
@keyframes fadeInBack {
0%{opacity:0;transform:scale(0)}
20%{opacity:.2;transform:scale(0.2)}
40%{opacity:.4;transform:scale(0.4)}
60%{opacity:.6;transform:scale(0.6)}
80%{opacity:.8;transform:scale(0.8)}
100%{opacity:1;transform:scale(1)}
}
@-webkit-keyframes blurFadeInOut {
0%{opacity:0;text-shadow:0 0 40px #fff;-webkit-transform:scale(1.3)}
20%,75%{opacity:1;text-shadow:0 0 1px #fff;-webkit-transform:scale(1)}
100%{opacity:1;text-shadow:0 0 50px #fff;-webkit-transform:scale(1);color:#fff}
}
@-webkit-keyframes blurFadeIn {
0%{opacity:0;text-shadow:0 0 40px #fff;-webkit-transform:scale(1.3)}
50%{opacity:.5;text-shadow:0 0 10px #fff;-webkit-transform:scale(1.1)}
100%{opacity:1;text-shadow:0 0 1px #fff;-webkit-transform:scale(1)}
}
.login h2.frame-1{animation-delay:0;color:#fff;margin-top:2rem}
.login h2.frame-5{-webkit-animation:none;-moz-animation:none;-ms-animation:none;animation:none;color:transparent;text-shadow:0 0 1px #cfcfcf;font-size:25px}
.login h2.frame-5 span{animation:blurFadeIn 2s ease-in 3s backwards;color:transparent;text-shadow:0 0 1px #fff}
.login h2.frame-5 span:nth-child(2){animation-delay:4s}
.login h2.frame-5 span:nth-child(3){animation-delay:5s}
.header input{background-color:#4d5ec1;box-shadow:none;padding:10px 5px;border-radius:.25rem;color:#f2f2f2;transition:all .2s ease-in-out;width:50%}
.header input:focus{background-color:#fff;color:#333}
.padding-left-min{padding-left:var(--padding)}
.header .avatar svg{border-radius:50%;margin-right:.5rem}
.logo svg path{fill:#241F20}
.header .avatar,.m-right{margin-right:.5em}
.header .avatar svg{width:30px;background:#fff;height:30px;padding:7px}
.header .btn-dark.btn{background:none;border:2px solid #241F20;border-radius:50px;color:#241F20;font-family:itc-avant-garde-gothic-pro,sans-serif;text-transform:uppercase;font-weight:600;font-size:12px;letter-spacing:2px;padding:8px 22px;line-height:12px;display:inline}
.imgUpload,.align-left{text-align:left}
.select-Img .select-image{display:flex;justify-content:center}
.select-Img .select-image img{width:64px;margin:auto 2rem}
.select-Img .select-image div{width:124px;text-align:center;cursor:pointer}
.img-example,.col-12.img-example{display:block;max-height:6rem;width:auto;margin-bottom:2rem}
.contract-marca .input-group-text,.min-width-12{min-width:12rem}
.menu h2{margin:0;padding:0 8px;background-color:#1d1d1d;color:#fff;height:75px;line-height:75px}
.menu p.text{margin:0;padding:8px;color:#ddd}
.menu ul{list-style-type:none;margin:0;padding:2rem 0 0}
.container-form h1{color:#9BC4B6;font-size:2em;text-align:center;font-family:"Avenir Next",sans-serif;font-weight:700;margin:2rem auto}
.container-form h4{color:#69727b;font-size:16px;font-family:"Avenir Next",sans-serif;text-align:center;margin:var(--padding) auto}
.div-conteiner{margin:.5rem auto}
.container-form label{font-size:14px;color:#4f4f4f}
.espaciado{height:1rem}
textarea.form-control{min-height:100px}
.note{padding-top:0;text-align:justify}
.mode-edit{font-weight:700}
.container-button{float:left;margin:.5rem 0;padding-left:15px}
.label-without-spacing{margin-bottom:0}
.label-without-spacing .espaciado{height:0}
.error{color:#f03;font-weight:500;width:100%;font-size:.9rem}
.alert-danger,.alert.alert-danger{color:#fff;background-color:red;border-color:red}
input.error,select.error,.error input,.error select{border-color:#f03}
.error .input-group-text{background:#f03;color:#FFF;border-color:#f03}
.error-tr{background:#FFB279}
.error-campaing{    color: #fff;
  background-color: red;
  border-color: red;
  padding: 1rem;
  border-radius: 20px 0px;
  margin-bottom: 1rem;    display: flex;
  align-items: center;
  gap: 1rem;}
  .error-campaing a, .error-campainga:visited, .error-campaing a:hover{color: #fff;}
.info{width:15px;margin-left:10px;cursor:pointer;position:relative;top:-2px}
.icon-search-text{position:relative;margin-top:-2.7rem;font-size:2rem;margin-left:95%;transition:transform .3s ease;cursor:pointer}
.icon-search-text.active{transform:rotate(180deg)}
.icon-search-icon{position:absolute;margin-top:6px;margin-left:15px}
.form-control.active{padding-left:40px}
.search-text{position:absolute;background:#fff;border-radius:0 0 5px 5px;cursor:pointer;margin-top:-5px;border:1px solid #000;z-index:1;max-height:50vh;overflow:auto;min-width:59%;box-shadow:0 18px 19px 0 #200000 0 2px 5px 0 #520000}
.search-text ul{list-style:none;padding:0;margin-bottom:0}
.search-text ul li{padding:5px 10px}
.search-text ul li.active{display:flex;justify-content:space-between}
.search-text ul li:hover{background:#a3a3a3}
.tooltip-text{position:absolute;background:#fff;width:17rem;padding:1rem}
.tooltip-component{font-size:.9rem;line-height:20px;box-shadow:7px 2px 15px 6px #000 0 2px 12px 0 #000;position:absolute;z-index:99;padding:10px;background:#fff;border-radius:5px;max-width:16rem;margin-top:5px;color:#212529}
.tooltip-component-bubble{display:inline-flex}
.tooltip-component-bubble:after{content:"";position:absolute;border-style:solid;display:block;width:0;border-width:0 10px 10px;border-color:#ffff transparent;margin-left:-18px;z-index:999;margin-top:5px}
.per-cent-30{width:30%}
.per-cent-50{width:50%}
.per-cent-90{width:90%}
.table thead th{text-align:center;font-size:.8rem}
.tr-active{background:#FFFDF2}
.tr-shipping{background:#F5FFF2}
.right{float:right}
.btn-success.btn{background-color:#234E79;border-color:#234E79}
.btn-success.btn:hover{background-color:#9BC4B6;border-color:#9BC4B6}
.container hr{border-top-color:#9BC4B6}
.input-group.mb-3{min-width:100px}
.input-multiple-min .input-group-text{min-width:16rem}
.label-link label{text-decoration:underline;font-weight:700}
.content-table-sub-row{display:flex;justify-content:space-between}
.signature{width:100%;border-bottom:1px dotted #ccc;height:40px;margin:-24px 0;min-width:10rem}
.iframe-external{width:100%;height:100vh}
.iframe-print{width:100%;min-height:80vh}
.btn.print{padding:.375rem .75rem;margin-right:.5rem}
.btn.print.last{margin-right:0}
.print-page{margin:1rem}
.print-page .print-logo img{height:150px}
.print-page .print-note{flex:1}
.print-page .content-loading{margin-top:2rem}
.print-page table{width:100%;margin-bottom:.5rem}
.print-page table thead tr th{border-top:2px solid #333;border-bottom:2px solid #aaa;padding:1rem 0}
.print-page table tbody tr td{border-bottom:2px solid #aaa;padding:.8rem .4rem}
.print-page table tbody tr td.max-width-15{max-width:15rem}
.print-page table tbody tr:nth-child(odd) td{background-color:#ccc}
.print-page .header-print,.print-page .table-subtotal,.print-page .print-destination{margin-bottom:3rem}
.print-page .header-print{margin-top:2rem}
.print-page .print-total{border-bottom:2px solid #ccc}
.print-page .subtotal,.print-page .print-total{padding:.8rem 0}
.print-page .print-total.total{border-bottom:2px solid #333;border-top:2px solid #333;font-weight:700}
.content-widget{border:.1rem solid var(--p-border-subdued);overflow:hidden}
.content-widget table:not(.CalendarMonth_table){width:calc(100% - 1.6rem);margin:var(--padding-table)}
.content-widget table thead th{padding:var(--padding-table) var(--padding-table) var(--padding-table) 0;text-align:left;font-weight:500;white-space:nowrap;border:0}
.content-widget table thead th:last-of-type{padding-right:.5rem}
.content-widget table tbody td:not(.CalendarDay){text-align:left;padding:var(--padding-table) var(--padding-table) var(--padding-table) 0;box-shadow:0 -.1rem 0 0 #dfe3e8}
.content-widget table tbody td:last-of-type:not(.CalendarDay){padding-right:.5rem}
.content-widget table tbody td:not(.CalendarDay).nowrap,.nowrap{white-space:nowrap}
.content-widget table tbody td:not(.CalendarDay).ellipsis{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:20%}
.send{background:#f6f3c3;padding:4px 8px;border-radius:12px;font-size:.8rem;line-height:1.2rem;font-weight:500;color:#d4761f}
.send.ok{background-color:var(--send-ok-color);color:#FFF}
.send.partial{background-color:#FFA167;color:#fff}
.send i{padding-right:2px}
.filter-list{display:flex;flex-wrap:nowrap;margin:0;padding:0;list-style:none;border-bottom:.1rem solid var(--p-border-subdued);padding:var(--padding-table)}
.content-button{border-bottom:.1rem solid var(--p-border-subdued);padding:var(--padding-table)}
.content-button .filter-list{border-bottom:none;padding:0}
.content-button button{margin-top:0}
.select-recharge.select-text{width:100%;color:#fff;padding:5px;margin-bottom:1rem}
.select-recharge option{background:#000}
.filter-list li{display:flex;margin:0;padding-right:.8rem}
footer{margin-bottom:2rem}
.filter-list li a{position:relative;justify-content:center;width:100%;min-width:100%;margin-bottom:-2px;outline:none;text-align:center;white-space:nowrap;text-decoration:none;font-weight:500;color:#212b36;background-color:initial;padding:.4rem .8rem;border-radius:3px;border:.1rem solid transparent;box-shadow:0 0 0 0 transparent;font-size:1rem;cursor:pointer}
.filter-list li .form-control{border:none;font-weight:500;color:#212b36;border-bottom:1px solid #212b36;border-radius:0;height:2rem;line-height:1rem;position:relative;top:4px;padding:.4rem .8rem}
.filter-list li a.active,.filter-list li.active .form-control{background-color:rgba(92,106,196,.15);color:#202e78}
.filter-table{padding:var(--padding-table);padding-bottom:0}
.filter-table input,.filter-table select,.select-text{font-size:1rem;font-weight:400;line-height:2rem;letter-spacing:normal;padding:.2rem 1rem;background:none;border:.1rem solid var(--p-border);border-radius:var(--p-border-radius-base);box-shadow:inset 0 1px 0 0 rgba(99,115,129,.05)}
.filter-table input,.filter-table .content-input-search{position:relative;z-index:20;display:block;flex:1 1;width:100%;min-width:0;margin:0}
.filter-table .content-input-search span{line-height:1.5rem;font-size:.8rem}
.filter-table select{position:absolute;margin-top:-42px;z-index:20;right:2rem;height:42px;border:none;border-left:.1rem solid var(--p-border)}
.filter-table .filter select{position:initial;margin-top:0;width:100%;border:.1rem solid var(--p-border)}
.filter-table select:nth-last-of-type(2){right:12rem}
.content-loading{background:#d1d1d1;width:calc(100% - 3.7rem);position:absolute;margin-top:-40px;border-radius:5px}
.content-loading .loading{display:flex;align-items:center;justify-content:center;height:40px}
.content-loading.returns-loading{width:calc(100% - 8.7rem)}
.stage .alert{padding:.2rem;margin:0;border-radius:0 0 .25rem .25rem;font-size:14px;width:90%}
.td-stage .stage .form-control{padding:0;width:90%;margin:0;line-height:1rem;height:2rem;border:none;border-bottom:1px solid;border-radius:0}
.preloaderHtml{position:absolute;margin-top:5px;margin-left:5px;width:30px;height:30px;border:5px solid transparent;border-top:5px solid var(--color-primary);border-radius:50%;animation-name:girar;animation-duration:2s;animation-iteration-count:infinite;animation-timing-function:linear}
@keyframes girar {
from{transform:rotate(0deg)}
to{transform:rotate(360deg)}
}
.alert .preloaderHtml{position:relative;margin-right:1rem}
#preloader{position:relative;margin-right:70px}
#preloader span{display:block;bottom:0;width:9px;height:5px;background:#9b59b6;position:absolute;animation:preloader 1.5s infinite ease-in-out}
#preloader span:nth-child(2){left:11px;animation-delay:.2s}
#preloader span:nth-child(3){left:22px;animation-delay:.4s}
#preloader span:nth-child(4){left:33px;animation-delay:.6s}
#preloader span:nth-child(5){left:44px;animation-delay:.8s}
@keyframes preloader {
0%{height:5px;transform:translateY(0px);background:#9b59b6}
25%{height:30px;transform:translateY(15px);background:#3498db}
50%{height:5px;transform:translateY(0px);background:#9b59b6}
100%{height:5px;transform:translateY(0px);background:#9b59b6}
}
.pickups h4{padding:1rem 1rem 0;height:3rem}
.orden .title{display:flex;align-items:baseline;white-space:nowrap}
.orden .title .sub-title{padding:0 var(--padding)}
.orden .content-widget{padding:var(--padding);margin-bottom:var(--padding)}
.orden .address-order{border-top:1px solid rgba(0,0,0,0.1);margin:1rem -1rem;padding:var(--padding)}
.empaque .content-widget{padding:var(--padding)}
.orden .content-widget:nth-child(1){flex:1;margin-right:var(--padding)}
.orden .content-widget.not-margin-right{margin-right:0}
.orden .content-loading{margin:0;width:calc(100% - 2rem)}
.orden .content-loading .loading{height:50px}
.orden .content-widget table{margin:var(--padding) 0;width:100%}
.orden .content-widget.client{flex:0 0 35%}
.orden .content-widget.client.label{flex:0 0 685px}
table tr.active{background:var(--color-active);color:#fff}
.pdf .pdf-viewer{width:100%;height:calc(100vh - 76px)}
.pdf .content-loading,.about .content-loading,.recharge .content-loading{margin-top:0;width:calc(100% - 2rem)}
.content-loading.witch-table{position:relative;width:100%}
.pdf .load{max-width:300px;margin-top:60px}
.recharge .content-widget:nth-child(1){margin-right:0}
.about .content-widget{padding:var(--padding);margin-bottom:1rem}
.about .content-loading{width:calc(100% - 2rem)}
.about .content-widget > h1,.about .content-widget > h2{padding-left:1rem}
.preferences-detail{min-width:350px}
.preferences-detail .label,.margin-bottom-1{margin-bottom:1rem}
.preferences .space-between .label{flex:0 0 50%}
.preferences .space-between .label:first-of-type{padding-right:1rem}
.input-text{font-size:1rem;font-weight:400;line-height:2rem;letter-spacing:normal;padding:.2rem 1rem;background:none;border:.1rem solid var(--p-border);border-radius:var(--p-border-radius-base);box-shadow:inset 0 1px 0 0 rgba(99,115,129,.05);min-width:300px}
.imput-align-text{display:flex;flex-wrap:wrap;justify-content:center;align-items:center}
.card{margin:15px!important;border-radius:5px!important;padding:2px;height:120px;width:180px;background-color:#63a1f1!important;border-color:#8a94a0!important;border-style:double;color:#fff;text-shadow:1px 1px rgba(0,0,0,0.411);text-align:center}
.card-green{background-color:green!important}
.card-blue{background-color:#238ea1c5!important}
.card-lightgreen{background-color:#31d683b0!important}
.card-pink{background-color:#f146469c!important}
.card-container{display:inline-flex;max-width:100%;padding-left:15%;padding-right:15%;justify-content:space-between}
.dataselector{padding-right:10px}
.midline{padding-top:28px}
.local-orders .list-orders .item-order{display:flex;align-items:center}
.local-orders .list-orders .fix.item-order{display:flex;align-items:center;background-color:#ececec}
.local-orders .list-orders .items .items-dd::before{content:"";position:absolute;top:12rem;bottom:13.5rem;left:24px;width:1px;border-left:1px dotted #f16622;z-index:0}
.local-orders .list-orders .items .items-dd .item-dd > div:hover{background:#E4FFDD!important}
.local-orders .list-orders .items select{margin:1rem;width:calc(100% - 1rem)}
.local-orders .list-orders .item-order .number{margin:1rem;background:#f16622;width:18px;height:18px;line-height:18px;border-radius:50%;text-align:center;box-shadow:#fff 0 0 0 2px;box-sizing:border-box;color:#fff;font-size:.714em;z-index:2}
.local-orders .list-orders .item-order .address{border-bottom:1px solid #b4b4b4;padding:.8rem 0;width:100%}
.local-orders .list-orders .item-order.shop{background:var(--color-primary);color:#fff}
.local-orders .cost{padding:1rem;text-align:right;background:#6371c7;color:#fff;margin-left:1rem;margin-top:1rem}
.local-orders .cost span{min-width:4rem;display:inline-block}
.icon-delete{position:absolute;right:1rem;cursor:pointer}
.insured > div{padding-left:1rem}
.content-flex.flex-center.content-pickup{margin-bottom:0}
.content-pickup .insured > div > div{display:flex}
.content-pickup .form-check{padding:0}
.content-pickup .form-check:nth-child(2){padding-left:2rem}
.content-pickup-datePicker.input-group{width:11.4rem}
div.input-ghost{min-height:50px}
.img-prod{width:5rem}
.container-map > div:nth-child(1){flex:1 0 40%;position:relative;padding-right:1rem}
.container-map > div:nth-child(2){flex:1 0 60%;position:relative;height:calc(100vh - 150px);min-width:60%}
.container-map > div:nth-child(2) .container-loading{height:calc(100vh - 150px);align-items:center;display:flex;justify-content:center;background:#CCC}
.email-template{border:.1rem solid var(--p-border-subdued);padding:1rem;background-color:var(--background-table-color);margin-right:1rem;flex:1 1 auto}
.email-help{border:.1rem solid var(--p-border-subdued);padding:1rem;flex:0 0 15rem;background-color:var(--background-table-color)}
.email-help .shortcode p{color:var(--background-table-color);background:var(--color-primary);padding:.4rem .5rem;cursor:pointer}
.help-copy{position:absolute;font-size:.715rem;background:#000;color:#fff;padding:.2rem .5rem;margin-top:-1rem}
.definition-simulate-email{width:25%}
.simulate-email{width:75%;margin:1rem;border:1px solid #ccc;padding:1rem;background:#ccc}
.text-area textarea{height:300px}
.crate-wa textarea{height:150px;form-sizing:content;resize:none}
.crate-wa.tabselect-1 textarea{height:80px;}
.create-wa-cards{
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  overflow: auto;
  padding-bottom: 2rem;
}
.create-wa-cards .wa-card{
  min-width: 320px;
}
.cont-wp .create-wa-cards, .cont-wa-item .create-wa-cards{
  margin-top: 0.2rem;
  gap: 0.2rem;
}
.cont-wp .create-wa-cards .wp, .cont-wa-item .create-wa-cards .wp{
  min-width: 250px;
  border-radius: 5px;
}
.cont-wp .create-wa-cards .wp:after, .cont-wa-item .create-wa-cards .wp:after{
  display: none;
}
.select-icon{width:55px;border:3px solid #cfcfcf;padding:5px;border-radius:3px;cursor:pointer;margin-right:5px}
.select-icon.active{border-color:#5563c1}
.switchBtn{position:relative;display:inline-block;min-width:41px;height:26px;margin:0 .5rem}
.switchBtn input{display:none}
.switchBtn .slide{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s;padding:4px 8px;color:#fff}
.switchBtn .slide:before{position:absolute;content:"";height:20px;width:20px;left:18px;bottom:3px;background-color:#fff;-webkit-transition:.4s;transition:.4s}
.switchBtn input:checked + .slide{background-color:#000}
.switchBtn input + .slide .active{display:none}
.switchBtn input + .slide .disabled{display:block;font-size:.9rem;line-height:30px}
.switchBtn input:checked + .slide .disabled{display:none}
.switchBtn input:checked + .slide .active{display:block}
.switchBtn input:focus + .slide{box-shadow:0 0 1px #01aeed}
.switchBtn input:checked + .slide:before{-webkit-transform:translateX(26px);-ms-transform:translateX(26px);transform:translateX(26px);left:-23px}
.switchBtn .slide.round{border-radius:26px}
.switchBtn .slide.round:before{border-radius:50%}
.switchBtn.orderSwitchBtn{width:130px}
.switchBtn-text{line-height:34px;font-weight:700}
p.no-margin,.no-margin{margin:0}
.switchBtn.orderSwitchBtn .slide:before{left:100px}
.switchBtn.large{width:11rem}
.switchBtn.large .slide:before{left:145px}
.img-returns{width:8rem;max-height:5rem}
.img-return{max-width:100%}
.img-return-shopify{max-width:100%;border:1px solid #ccc;padding:.5rem;border-radius:3px;margin-bottom:1rem}
pre.pre-shopify{width:100%;white-space:pre-wrap;border:1px solid #ccc;padding:.5rem;border-radius:3px}
.returns-shopify h3{margin-top:2rem}
.icon-td img{width:2rem}
.select-image label{margin:0}
.select-image .select-image-icon img{width:2rem;margin:0 1rem}
.select-image .select-image-list{position:absolute;display:flex;position:absolute;display:flex;width:40%;flex-wrap:wrap;z-index:9;background:#fff;padding:1rem;cursor:pointer;border:1px solid #ccc;border-radius:3px}
.select-image .select-image-list img{margin:.5rem;width:2rem}
.select-image .select-image-list .active{background-color:#8CE196}
.tabs{position:relative;z-index:1;top:1px}
.tab{display:block;padding:1rem 2rem;border:1px solid #ccc;border-bottom:none;cursor:pointer;background:#90CAF9;transition:background ease .2s;color:#666}
.tab.pred0,.tab.manual1{background:#fff;font-weight:700;color:#333}
.tabs-content{border:1px solid #ccc;padding:1rem}
.content-upload-image .content-loading{position:relative;min-height:15rem;display:flex;align-items:center;justify-content:center}
.content-upload-image img:nth-child(1),.content-upload-image img:nth-child(3){flex:1 0 20%;max-width:20%;border-radius:5px;border:5px solid green;padding:.5rem;max-height:15rem}
.content-upload-image img:nth-child(2){flex:1 0 20%;max-width:50px;margin:0 10px}
.button-center{text-align:center;margin-top:1rem;margin-bottom:2rem}
.content-upload-image img:nth-child(1){border-color:#23d160}
.content-upload-image img.no-border{border:none;margin-right:1rem}
.content-upload-image > div {margin-bottom: 1rem;}
.steps{display:flex;list-style:none;justify-content:space-between;padding-left:0}
.steps.has-content-cente#ff0033{text-align:center}
.steps.is-medium{font-size:1rem}
.steps .steps-segment{position:relative;flex:1}
.steps .steps-segment:not(:last-child){flex-basis:1rem;flex-grow:1;flex-shrink:1}
.steps .steps-marker{background-color:#23d160;color:#fff}
.steps .steps-marker i{font-size:1rem}
.steps.is-medium.has-content-cente#ff0033 .steps-marker{position:absolute;left:calc(50% - 1.25rem)}
.steps.is-medium.has-content-cente#ff0033 .steps-content{margin-top:3.5rem;margin-left:.5em;margin-right:.5em;padding-top:.2em}
.steps.is-medium .steps-content{padding-right:1rem}
.steps.is-medium .steps-content.top{margin-top:5px;margin-bottom:.5rem}
.steps.is-medium .steps-content.top .is-size-4{margin-bottom:0}
.heading{display:block;font-size:11px;letter-spacing:1px;margin-bottom:5px;text-transform:uppercase}
.steps.is-medium .steps-segment:not(:last-child):after{height:.4em;left:2.26rem;right:-.24rem;top:calc(3.6rem - (.2em))}
.steps.is-medium.has-content-cente#ff0033 .steps-segment:not(:last-child):after{left:50%;right:-50%}
.steps .steps-segment:not(:last-child):after{content:" ";display:block;position:absolute}
.steps-segment:after{background-color:var(--step-success)}
.steps.is-medium.has-content-cente#ff0033 .steps-marker{position:absolute;left:calc(50% - 1.25rem)}
.steps .steps-marker{background-color:var(--step-success);color:#fff}
.steps.is-medium .steps-marker{height:2.5rem;width:2.5rem;overflow:hidden}
.steps .steps-marker{align-items:center;display:flex;border-radius:50%;font-weight:700;justify-content:center;position:relative;z-index:5}
.steps-segment.is-active ~ .steps-segment .steps-marker.is-hollow{border-color:#dbdbdb}
.steps.is-medium:not(.is-vertical):not(.is-thin).has-content-cente#ff0033 .steps-segment.has-gaps:not(:last-child):after{left:calc(50% + 1.55rem);right:calc(-50% + 1.55rem)}
.steps-segment.is-active ~ .steps-segment:after,.steps-segment.is-active:after{background-color:#dbdbdb;margin-left:.4rem;border-radius:3px;margin-right:.4rem}
.steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow){background-color:#dbdbdb;color:rgba(0,0,0,0.7)}
.steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment.is-current .steps-marker:not(.is-hollow){background:#FFE13E}
.steps-segment.is-current{font-weight:700}
.steps.is-medium:not(.is-vertical):not(.is-thin).has-content-cente#ff0033 .steps-segment.has-gaps:not(:last-child):after{left:calc(50% + 1.55rem);right:calc(-50% + 1.55rem)}
.steps:not(.is-vertical).is-narrow.has-content-cente#ff0033 .steps-segment:last-child{flex-grow:0;min-width:10em}
.Polaris-Card_yis1o:not(:first-child){margin-left:1rem}
.Polaris-Card_yis1o{border-radius:var(--p-border-radius-wide)}
._3r3-r{padding:.8rem}
._1VUFI{color:var(--p-text-subdued);margin-bottom:.8rem}
._3WdC8{display:flex;flex-direction:column;justify-content:space-between;min-height:3rem}
.Polaris-Stack--noWrap_vecks{flex-wrap:nowrap}
.Polaris-Stack--spacingExtraTight_gv6hw{margin-top:-.4rem;margin-left:-.4rem}
.Polaris-Stack--distributionEqualSpacing_x9cqm{justify-content:space-between}
.Polaris-Stack--alignmentBaseline_aupj5{align-items:baseline}
.Polaris-Stack__Item_yiyol{flex:0 0 auto;min-width:0}
.Polaris-Stack--spacingExtraTight_gv6hw>.Polaris-Stack__Item_yiyol{margin-top:.4rem;margin-left:.4rem;max-width:100%}
._3vPiE{font-size:1.6rem;line-height:.8rem}
.Polaris-Subheading_syouu{font-size:1.5rem;margin-bottom:0}
._3cnsH,._3vPiE{color:#212b36;font-weight:600}
.Polaris-Card_yis1o{background-color:var(--p-surface);box-shadow:var(--p-card-shadow);outline:.1rem solid transparent}
.Polaris-TextStyle--variationSubdued_1segu{color:var(--p-text-subdued)}
.Polaris-Card_yis1o{flex:1;margin-bottom:1rem}
.items-icon{display:flex;justify-content:center;flex-direction:column}
.items-icon img{width:55px}
.items-icon .item-order{text-align:center}
.margin-1-0{margin:1rem 0}
.state-shipping i{font-size:2rem}
.state-shipping > div{display:flex;align-items:center}
.state-shipping > div > span{padding-left:.3rem}
.content-alert{position:absolute;z-index:1;left:0;width:100%}
.shippings .alert{margin:1rem}
.max-width-normal{max-width:22rem}
.container-multi-footer.content-flex.flex-end{align-items:center}
.container-multi-footer .content-flex{align-items:center;margin-right:3rem}
.container-multi-footer .content-flex > div{margin-left:1rem}
.container-multi-footer .btn{margin-top:0}
.btn.btn-info:disabled{background:grey}
.alert.alert-warning{font-size:.8rem}
.space{padding:var(--padding)}
.logo-carrier{width:31px}
.content-input{display:flex}
.content-input .input-group-text{padding:0 .5rem}
.content-input .input-group-prepend .input-group-text{border-radius:3px 0 0 3px;min-width:12rem}
.content-input .input-group-append .input-group-text{border-radius:0 3px 3px 0}
.preferences-install-help img{max-width:100%;margin:.5rem 0 1rem}
.form-group .datePicker > div{width:100%}
.form-group .datePicker .icofont-ui-calendar,.form-group .datePicker .icofont-clock-time{position:absolute;left:0;font-size:1.5rem;padding:6px 2px 6px 6px;z-index:3}
.form-group .datePicker input{padding-left:35px}
.width-20{width:20%}
.width-15{width:15%}
.readonly .input-group-text{background:transparent;border:none;padding:0}
.readonly .text{border:none;padding-left:0;font-weight:700}
.address-order .readonly .text{font-weight:400}
.label-dhl{max-width:650px}
.label-dhl-text{position:absolute;margin-top:52px;font-size:.8rem;margin-left:70px;font-weight:700}
.label-dhl-text div{line-height:.8rem}
.label{margin-bottom:1rem}
.label.label-check{display:flex;align-items:center}
.label.label-check .switchBtn{margin-left:1rem;margin-right:2rem}
.m-left{margin-left:2rem}
.no-data{margin:2rem 0}
.no-data i{font-size:2rem;margin-bottom:1rem}
.flex-two-element div{flex:1 1 50%}
table tbody tr:hover{background-color:#e1e1e1}
table tbody tr.no-background:hover{background-color:transparent}
.shopify{color:#95BF47}
.destination{font-size:.8rem;font-weight:100;margin-left:1rem}
.destination.carrier{margin-left:37px}
.without-left{margin-left:0}
.destination.orden-info{margin-left:1.4rem}
.center,.content-widget table thead th.center,.content-widget table tbody td.center{text-align:center}
.text-right{text-align:right}
.total{max-width:100%}
.click{cursor:pointer}
.big-font-size{font-size:1.5rem}
.insu#ff0033 div div{display:flex}
.insu#ff0033 div div .form-check{margin:0 .5rem}
.content-flex.space-between{justify-content:space-between;align-items:center}
.content-input-filter{width:18rem;margin-left:1rem}
.content-select-filter{width:20rem;margin-left:1rem}
.content-flex.flex-center-left.form .form-control{margin-left:1rem}
.content-package{background-image:url(/img/caja_personalizado.png);background-repeat:no-repeat;background-position:center;min-height:245px;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;background-size:300px}
p.specification,div.specification{font-weight:700;display:flex;align-items:center}
p.specification span,div.specification sapn{font-weight:100}
p.specification input,div.specification input{margin:0 .3rem}
.content-package p:nth-child(1),.content-package div:nth-child(1){margin-right:-435px}
.cont-width{position:relative;margin-top:-45px}
.cont-width p:nth-child(1){margin-left:4rem;margin-top:-1rem}
.cont-width div:nth-child(1){margin-left:5rem;margin-top:-1rem;margin-right:2rem}
.cont-width p:nth-child(2){padding-left:6rem;margin-top:-1rem}
.cont-weight{margin-left:6rem}
.cont-width div:nth-child(2){padding-left:2rem;margin-top:-1rem}
.content-package p input,.cont-width p input,.cont-weight p input,.content-package div input,.cont-width div input,.cont-weight div input{width:3.5rem}
.spacing-big{padding:3rem}
.spacing-medium{padding:1.5rem}
.spacing-small{padding:1rem}
.spacing-small-top{padding-top:1rem}
.spacing-small-left{padding:0 0 0 1rem}
.height-70{height:70px}
.client p,.client span.text{font-size:.9rem;margin-bottom:0;line-height:1.5rem}
.client .label .false.readonly{margin-bottom:-.5rem}
.direction-selected button{margin-left:1em}
button.btn{padding:.2rem 1rem;white-space:nowrap}
.btn.triciclo{background:-webkit-gradient(linear,left top,left bottom,from(#6371c7),to(#5563c1));background:linear-gradient(to bottom,#6371c7,#5563c1);border-color:#3f4eae;-webkit-box-shadow:inset 0 1px 0 0 #6f7bcb;box-shadow:inset 0 1px 0 0 #6f7bcb;color:#FFF}
.link-small{padding:0!important;margin:0!important}
.btn-icon{color:#007bff}
.btn-icon:hover{color:#0056b3}
.top-middle{margin-top:.5rem}
.bottom-middle{margin-bottom:.5rem}
.right{float:right}
.error-server .content-widget{text-align:center;padding:1rem}
._21Z9T{display:inline-flex;align-items:center;padding:.1rem .5rem;background-color:#dfe3e8;border-radius:2rem;font-size:.7rem;line-height:1rem;color:#454f5b}
.-EFlq{height:.7rem;width:.7rem;margin:0 .2rem 0 -.2rem;border:.2rem solid currentColor;border-radius:50%;flex-shrink:0}
._1EPFc .-EFlq{background:currentColor}
._33uWB .-EFlq{background:transparent;color:#c05717}
._21Z9T .-EFlq{color:#919eab}
._3o7e3{background-color:#ffc58b;color:#594430}
._3o7e3 .-EFlq{color:#c05717}
.content-social{position:relative}
.content-social img{position:absolute;height:50px;bottom:-8px;left:-4px;cursor:pointer;opacity:.7}
.content-social img:hover{opacity:1}
.modal-body .text.social-item{display:flex;align-items:center;cursor:pointer;width:100%;justify-content:space-between}
.modal-body .text.social-item-hide{opacity:.5;margin:.3rem 0}
.social-item img{width:1.5rem;margin-left:.5rem;cursor:pointer}
.social-mode img{width:29px;cursor:pointer}
.modal-body .text > div{margin-top:.5rem}
.social-size{width:25px;height:25px;border-radius:9999px;border:2px solid rgba(124,131,141,1);cursor:pointer}
.social-size .social-size-in{border-radius:9999px;background:rgba(124,131,141,1);cursor:pointer}
.social-size .social-size-in.size-0{width:5px;height:5px;margin:10px}
.social-size .social-size-in.size-1{width:11px;height:11px;margin:7px}
.social-size .social-size-in.size-2{width:19px;height:19px;margin:3px}
.icon-links.hidden{opacity:.5;cursor:no-drop}
.modal-social-popup{display:none;position:fixed;z-index:99999;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:#000;background-color:rgba(0,0,0,0.4);-webkit-animation-name:fadeIn;-webkit-animation-duration:.4s;animation-name:fadeIn;animation-duration:.4s}
.modal-social-popup .modal-content{position:fixed;bottom:0;background-color:#fefefe;width:100%;-webkit-animation-name:slideIn;-webkit-animation-duration:.4s;animation-name:slideIn;animation-duration:.4s;margin:auto calc(50% - 600px);display:block;max-width:1200px;border-radius:16px 16px 0 0;height:calc(95vh - 60px);max-height:720px}
.modal-social-popup .close{float:right;font-size:28px;font-weight:700}
.modal-social-popup .close:hover,.modal-social-popup .close:focus{color:#000;text-decoration:none;cursor:pointer}
.modal-social-popup .modal-header{padding:2px 16px}
.modal-social-popup .modal-body{padding:16px;display:flex;justify-content:space-between;overflow:auto;height:100%;align-items:stretch;max-width:1500px;margin:auto}
.modal-social-popup .modal-footer{padding:2px 16px}
#browsers{    
  position: absolute;
  background: #fff;
  z-index: 999;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, .05);
    border: 1px solid rgb(229 231 235);
    border-radius: 20px 0px;
    padding: 1.5rem;
    max-height: 150px;
    overflow: auto;
}
#browsers > div{
  padding-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
}
#browsers > div > img{
  width: 42px;
  border-radius: 4px;
}
#browsers.selected{
  position: relative;
  padding: 8px;
  border: none;
  border-bottom: 3px solid var(--color-primary);
  border-radius: 1px;
  background: #efefef
}
.input-form.selected{
  border-radius: 6px 6px 0px 0px;
  border-color: var(--color-primary);
}
#browsers.selected > div{
  padding-bottom: 0px;
}
@-webkit-keyframes slideIn {
from{bottom:-300px;opacity:0}
to{bottom:0;opacity:1}
}
@keyframes slideIn {
from{bottom:-300px;opacity:0}
to{bottom:0;opacity:1}
}
@-webkit-keyframes fadeIn {
from{opacity:0}
to{opacity:1}
}
@keyframes fadeIn {
from{opacity:0}
to{opacity:1}
}
.iframe-content{border:none;height:1px;width:1px}
.input-form,.content-widget-tn select{box-sizing:border-box;width:100%;max-width:100%;vertical-align:top;height:28px;font-size:14px;display:inline-block;color:#222;padding:1rem .5rem;margin:0;    border: 1px solid rgb(229, 231, 235);
  border-radius: 6px;}
.cont-min .input-form{width:260px}
.cont-min .input-form[type="time"],.dateInput .input-form[type="time"]{width:130px}
.content-widget-tn select{padding:.3rem .5rem;height:34px}
input.input-form[type=color]{width:80px;border:none;background:transparent;padding:0;height:43px}
input.input-form[type=range]{border:none}
.promo-campaings{display:flex;flex-direction:column;gap:2rem;margin-bottom:6rem}
.promo-campaings ul{margin-top:0}
.promo-campaings ul li{line-height:2rem}
.content-reviews.promo-campaings .title{margin-bottom:0;margin-top:2rem}
.content-reviews.promo-campaings .title ~ .title{margin-top:-1rem}
.content-reviews .title{    margin-top: 1rem;
  margin-bottom: 0.5rem;}
  .errors-spread p{    color: red;
    font-size: 14px;
    letter-spacing: normal;
    line-height: 16px;
    font-weight: 100;}
    .errors-spread{height: 150px;
  overflow: auto;}
.promo-campaings .content-flex{gap:2rem}
.promo-campaings img{max-width:40%;border-radius:5px}
.scroll-table.scroll-table-min{overflow-x:auto}
.scroll-table.scroll-table-min table{min-width:600px;margin:0 var(--padding)}
.scroll-table.scroll-table-min table tbody tr td:first-of-type,.scroll-table.scroll-table-min table thead tr th:first-of-type{background:#fff;height:100%;visibility:visible;position:sticky;left:0;padding-left:var(--padding)}
.scroll-table.scroll-table-min.active table tbody tr td:first-of-type,.scroll-table.scroll-table-min.active table thead tr th:first-of-type{box-shadow:.1rem -.1rem 0 0 #dfe3e8}
input[type="date"]::-webkit-calendar-picker-indicator{background:transparent;bottom:0;color:transparent;cursor:pointer;height:auto;left:0;position:absolute;right:0;top:0;width:auto}
.tk-itc-avant-garde-gothic-pro{font-family:"itc-avant-garde-gothic-pro",sans-serif}
.resumen-saldo{text-align:center;color:#fff;padding:25px 12px;background:#000}
.saldo-title{background:none;font-size:15px;font-weight:700}
.saldo-number{background:none;padding:0;margin:0;line-height:normal;height:auto;font-weight:300;font-family:'itc-avant-garde-gothic-pro';font-size:30px;letter-spacing:1px;margin-bottom:15px}
a.recarga-btn{background:#fff;border-radius:50px;padding:12px 24px;color:#000;font-family:'itc-avant-garde-gothic-pro';display:inline-block;font-weight:700;text-transform:uppercase;font-size:12px;letter-spacing:1px;line-height:12px}
.Polaris-Card_yis1o{margin-bottom:2rem;box-shadow:2px 2px 5px 1px #000;border-radius:0;outline:none}
.Polaris-Card_yis1o:not(:first-child){margin-left:2rem}
._3r3-r{padding:2rem;text-align:center;font-family:'itc-avant-garde-gothic-pro'}
h3.Polaris-Subheading_syouu{font-size:16px;text-transform:uppercase;line-height:2;letter-spacing:1px}
.about .content-widget{padding:2rem;margin-bottom:2rem;box-shadow:2px 2px 5px 1px #000;border-radius:0;outline:none}
.about .content-widget>h1,.about .content-widget>h2{font-family:'itc-avant-garde-gothic-pro';font-weight:700;text-transform:uppercase;font-size:28px}
button.btn.triciclo.btn-link{background:#000;border:none;border-radius:50px;padding:10px 24px;font-family:'itc-avant-garde-gothic-pro';font-size:14px;color:#fff}
.apexcharts-text tspan{font-family:'itc-avant-garde-gothic-pro'}
button.btn.triciclo.right{background:none;color:#000;font-family:'itc-avant-garde-gothic-pro';font-size:14px;border:1px solid #000;border-radius:50px;box-shadow:none;padding:10px 24px}
.ordens h1{font-family:itc-avant-garde-gothic-pro;font-weight:700;text-transform:uppercase;font-size:28px}
a.btn.triciclo.top-middle.right{background:#000;text-transform:none;border:none;border-radius:50px;padding:10px 24px;font-family:itc-avant-garde-gothic-pro;font-size:14px;color:#fff}
.content-widget{padding:2rem;margin-bottom:2rem;box-shadow:2px 2px 5px 1px #000;border-radius:0;outline:none;font-family:'itc-avant-garde-gothic-pro'}
.filter-list li.active .form-control,.filter-list li a.active{background-color:#dfe3e8;color:#000;border-radius:0}
.filter-list li .form-control{border:1px solid #ccc;position:inherit;height:40px}
.btn.triciclo{border:none;border-radius:50px;padding:10px 24px;font-family:itc-avant-garde-gothic-pro;font-size:14px;background:#dfe3e7;box-shadow:none;color:#000}
.filter-table{display:flex;flex-flow:nowrap}
.form-check-conteiner input:disabled~.form-check-span{border:1px solid #ccc;border-radius:0;background:#ccc}
.form-check-span{border:1px solid #ccc;border-radius:0;background:#fff}
.disabled .form-check-span{background:#999;cursor:not-allowed}
.form-check-conteiner.disabled{background:#f3f3f3}
.form-check-conteiner.active.disabled{background:#fff}
.text-disabled{position:absolute;margin-top:-35px;background:var(--color-primary);border-radius:var(--radius);padding:5px;color:#fff;width:calc(100% - 9rem);text-align:center;margin-left:3rem}
i.icofont-grocery.shopify{display:none}
div#scroll-table a{color:#3fbaca;font-weight:600}
.destination.orden-info{margin:0;line-height:24px}
._21Z9T{background:#cfecc8;color:#68A15C}
._21Z9T .-EFlq{color:#6ca45d}
div#scroll-table tr:hover{background:#ececec}
span.send.ok{background-color:#90dce6;color:#237782}
.td-stage .stage .form-control{background:#dfe3e7;border:none;padding:5px 10px;height:40px;font-size:15px}
a.ver-shopify-btn{background:none;border:1px solid;border-radius:50px;color:#000!important;font-size:12px;font-weight:400!important;padding:6px 12px}
.content-widget table:not(.CalendarMonth_table) tbody td:not(.CalendarDay){font-size:15px;width:16%}
.content-widget table:not(.CalendarMonth_table) tbody td:first-of-type:not(.CalendarDay){width:4%}
.CalendarMonthGrid__horizontal{width:auto!important}
ul.list-element{list-style:none}
ul.list-element li{padding-bottom:.8rem;line-height:1.2rem}
ul.list-element :first-child{margin-bottom:1rem}
ul.list-element.only{margin-left:-2.3rem}
ul.list-element.list-special{list-style-type:disc}
ul.list-element.list-special :first-child{margin-bottom:0}
.image-update-plan:after{content:"UPDATE PLAN";font-size:5em;color:rgba(13,33,63,0.4);z-index:9999;display:flex;align-items:center;justify-content:center;position:absolute;top:0;right:0;bottom:0;left:-41%;-webkit-pointer-events:none;-moz-pointer-events:none;-ms-pointer-events:none;-o-pointer-events:none;pointer-events:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none}
.video{flex:1 0 var(--width-video-img);min-width:var(--width-video-img);min-height:40vh}
.presentacion button.btn:disabled{background:#f2f2f2;border:none}
.img-conf{width:100%;margin:1rem 0 2rem}
hr.conf{border-top:none;margin:3rem 0 1rem;border:1px solid #C7C7C7}
hr.internal{width:50%;margin:1rem 0 .5rem;border:none;background:linear-gradient(to right,#0D213F,#01D765);height:5px;border-radius:2px}
.icon-links{height:18px;margin-left:.5rem;cursor:pointer}
.card-home.card-home-bottom.home{margin-bottom:1.5rem}
.campaigns .content-flex.space-between-start.mobile-scroll.flex-direction-column-mobile{justify-content:flex-start;gap:8px}
.campaigns .card-home:first-child:nth-last-child(3),.campaigns .card-home:first-child:nth-last-child(3) ~ .card-home{flex:0 1 30%}
.campaigns .card-home:first-child:nth-last-child(5),.campaigns .card-home:first-child:nth-last-child(5) ~ .card-home{flex:0 1 18%}
.campaigns .card-home .title{margin-top:0;font-size:20px;line-height:25px}
.campaigns .card-home .review{font-size:15px;line-height:20px}
.campaigns .card-home .percentage{font-size:24px;line-height:37px;margin-left:.5rem;white-space:nowrap}
.link-markenting{padding:1rem;margin-top: 1rem;    gap: 8px;}
.link-markenting .text{margin-bottom:1rem;line-height:23px}
.template-whatsapp{width:40%}
.sentiment-content{font-size:12px;padding:.4rem .8rem;border-radius:5px;margin-top:5px;font-weight:bolder;text-transform:uppercase}
.sentiment-content.NEGATIVE{background:#FFECE9;color:#fd5754}
.sentiment-content.NEUTRAL{background:#fff6da;color:#f4a500}
.sentiment-content.MIXED{background:#FFFAB5;color:#99977B}
.sentiment-content.POSITIVE{background:#dff7ea;color:#139947}
.select-filter{line-height: 24px;align-items: center;margin-right: 5px;cursor:pointer;}
.select-filter .sentiment-content{padding: 0px 5px;margin: 0px;}
.select-filter .sentiment-content.POSITIVE.ON{background:#139947;color:#dff7ea}
.sentiment-content.NEGATIVE.ON{background:#fd5754;color:#FFECE9}
.sentiment-content.NEUTRAL.ON{background:#f4a500;color:#fff6da}
.sentiment-content.MIXED.ON{background:#99977B;color:#FFFAB5}
.content-filter-reviews{display: flex;flex-direction: column;align-items: flex-end;}
.content-filter-reviews input{margin-left: 5px;padding: 4px;border-radius: 5px;}
.gap16{gap: 16px;}
.content-products.cont-camp-prod{border-radius: 5px;
    height: 350px;
    overflow: auto;}
.box{box-shadow:rgba(255,255,255,0.698) 0 0 0 2px,rgba(0,0,0,0.5) 0 3px 6px}
.content-inic-widget{flex:0 1 270px}
#idIframe{flex:0 1 calc(100% - 300px);margin-left:30px}
.content-widget-tn.edit-code .content-inic-widget{flex:0 1 60%}
.help-aut{font-style:italic;font-size:12px;line-height:15px;color:#000}
#idIframe iframe{width:100%;height:1400px;border:2px solid #0D213F;border-radius:20px 2px}
#idIframe textarea{font-family:Consolas,"courier new";color:#dc143c;background-color:#f1f1f1;padding:2px;font-size:105%;height:700px;width:100%;border:2px solid #0D213F;border-radius:20px 2px}
.content-widget-tn ul li{line-height:28px;font-size:16px}
.tooltip{position:relative;display:inline-block}
.tooltip .tooltiptext{visibility:hidden;width:140px;background-color:#555;color:#fff;text-align:center;border-radius:6px;padding:5px;position:absolute;z-index:1;margin-left:-30px;margin-top:-70px;opacity:0;transition:opacity .3s}
.tooltip .tooltiptext::after{content:"";position:absolute;top:100%;left:50%;margin-left:-5px;border-width:5px;border-style:solid;border-color:#555 transparent transparent}
.tooltip:hover .tooltiptext{visibility:visible;opacity:1}
.context-tooltip{margin-right:1rem}
.content-btn{display: flex;gap: 2px;}.content-btn .button{border-radius: 5px;}
.success-content .content-flex{gap: 1rem; margin-bottom: 2rem;font-weight: 600;  }
.text-prom{    background-color: var(--main-bg-color);
    font-family: sans-serif;
    border-radius: 20px 0;
    padding: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem}
.success-content label, .text-prom label{font-weight: normal;}
.campaigns.PENDING .data-campaing > div, .campaigns.DRAFT .data-campaing > div, .campaigns.ERROR .data-campaing > div{    display: flex;
    align-items: flex-start;}
    .campaigns.PENDING .data-campaing > div .datail-campaing, .campaigns.DRAFT .data-campaing > div .datail-campaing, .campaigns.ERROR .data-campaing > div .datail-campaing{margin-top: 8px;}
.container {
flex-grow: 1;
margin: 0 auto;
position: relative;
width: auto;
}
.content-cvs{    display: flex;
  gap: 2rem;
  margin: 2rem 0px;}
  .content-cvs > div:nth-child(1){width: 50%;}

@media screen and (min-width: 1024px) {
.container {
    max-width: 960px;
}
}

@media screen and (min-width: 1204px) {
.container {
    max-width: 1140px;
}
.container {
    max-width: 1140px;
}
}


@media screen and (max-width: 768px) {
.hero {
    min-height: auto;
}
}

.hero .hero-body .campaign {
flex-direction: column;
display: flex;
align-items: baseline;
justify-content: center;
width: 100%;
}

.hero.connect-page {
min-height: auto;
}

.hero .hero-body {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

@media screen and (max-width: 768px) {
.hero .hero-body {
    padding: 2rem 0;
}
}

.section.pair .pair-steps .step .step-number .value {
color: #fff;
font-weight: 700;
font-size: 3rem;
}

.hero .hero-body .counter {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

@media screen and (max-width: 768px) {
.hero .hero-body .counter {
    margin: 2rem 0 0;
}
}

.hero .hero-body .counter .title {
color: #9c9c9c;
font-size: 1rem;
font-weight: 700;
letter-spacing: 2px;
text-transform: uppercase;
margin-bottom: 0.5rem;
text-shadow: 0 1px 2px #0003;
}

.hero .hero-body .counter .counter-boxes {
display: flex;
flex-direction: row;
}

.hero .hero-body .counter .counter-boxes .count-box {
background-color: var(--background-header);
box-shadow: 0 5px 10px #0000004d;
border-radius: 8px;
backdrop-filter: blur(5px);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 80px;
height: 80px;
margin-right: 4px;
text-shadow: 0 1px 0 #0000004d;
}
.frequent-questions{
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 16px;
}
.frequent-questions div{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.04);
    border-radius: 5px;
    font-weight: 700;
    color: #1c293b;
    font-size: 13.95px;
    letter-spacing: 0;
    line-height: 19px;
    padding: 10px 22px 12px 24px;
    display: block;
    background: #F3F6FB;
}
.frequent-questions div a, .frequent-questions div a:visited{
    height: 38px;
    color: #1c293b;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: space-between;
}
.frequent-questions div a:after {
    content: "";
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgOCAxMiIgd2lkdGg9IjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQuNDAyIDZMLjMzIDEwLjA3MWExLjEzIDEuMTMgMCAwIDAgMS41OTggMS41OThsNC45MTYtNC45MTZhMS4wNjQgMS4wNjQgMCAwIDAgMC0xLjUwNkwxLjkyOS4zM0ExLjEzIDEuMTMgMCAwIDAgLjMzMSAxLjkyOXoiIGZpbGw9IiMxYzI5M2IiLz48L3N2Zz4=);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 6px;
    height: 9px;
    margin-left: 6px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

@media screen and (max-width: 768px) {
.hero .hero-body .counter .counter-boxes .count-box {
    width: 70px;
    height: 70px;
}
}

.hero .hero-body .counter .counter-boxes .count-box h1 {
color: #fff;
padding: 8px 0 0;
margin: 0;
font-size: 2rem;
line-height: 2rem;
}

@media screen and (max-width: 768px) {
.hero .hero-body .counter .counter-boxes .count-box h1 {
    font-size: 1.5rem;
}
}

.hero .hero-body .counter .counter-boxes .count-box span {
color: #9c9c9c;
font-size: 0.75rem;
font-weight: 700;
text-transform: uppercase;
}

@media screen and (max-width: 768px) {
.hero .hero-body .counter .counter-boxes .count-box span {
    font-size: 10px;
    letter-spacing: 0;
}
}

.hero .hero-body .counter .counter-boxes .count-box:last-of-type {
margin-right: 0;
}

.container {
width: 100%;
}

@media only screen and (max-width: 768px) {
.col-1,.col-2,.col-3,.col-6,.col-8{width:100%!important;max-width:100%!important;flex:0 0 100%!important}
.scroll-table{overflow-x:auto}
.scroll-table table{min-width:600px;margin:0 var(--padding)}
.scroll-table table tbody tr td:first-of-type,.scroll-table table thead tr th:first-of-type{background:#fff;height:100%;visibility:visible;position:sticky;left:0;padding-left:var(--padding)}
.scroll-table.active table tbody tr td:first-of-type,.scroll-table.active table thead tr th:first-of-type{box-shadow:.1rem -.1rem 0 0 #dfe3e8}
.content-widget table{margin:var(--padding-table) 0}
.filter-table select{right:1rem}
.flex-two-element div{flex:1 1 100%}
.pdf .content-loading,.about .content-loading{width:calc(100% - 1rem)}
.card-container{padding-left:15%;padding-right:15%;flex-wrap:wrap}
}
@media (min-width:576px) {
.container,.container-sm{max-width:740px!important}
.card-container{flex-wrap:wrap}
}
@media (min-width: 768px) {
.container,.container-md,.container-sm{max-width:1200px!important}
.card-container{padding-left:15%;padding-right:15%;flex-wrap:wrap}
}
@media (min-width: 992px) {
.container,.container-lg,.container-md,.container-sm{max-width:1260px!important}
.card-container{padding-left:15%;padding-right:15%;flex-wrap:wrap}
}
.analytics .mixed-chart{width:calc(33.33% - 3rem)}
.campaigns .crate-wa{margin-right:1rem}
.campaigns .analytics{position:initial;width:49%;margin:0 1% 0 0;padding:8px 0rem;background:transparent}
.campaigns .analytics .mixed-chart{width:100%}
.campaigns .crate-wa-cont{width:49%;}
.campaigns .home{margin:1rem 0rem;overflow:auto}
.campaigns .text{flex:53%}
.content-img-prod{text-overflow:ellipsis;overflow:hidden;width:30%;margin-right:1rem}
.presentacion-login{display:flex;height:100vh;width:100%;justify-content:center;align-items:center;flex-direction:column}
.presentacion-login > div{display:flex;align-items:center;gap:40px}
@media (width < 1200px) {
.analytics .mixed-chart{width:calc(50% - 3rem)}
}
@media (width < 850px) {
.cont-total, .cont-total-esp{display:none!important}
.campaigns{flex-direction:column}
.campaigns .analytics{width:100%}
.data-campaing{flex-direction: column;}
.data-campaing > div{width: calc(100% - 2rem);}
}
@media (width < 750px) {
.analytics .mixed-chart{width:100%}
}
@media (min-width: 1200px) {
.container,.container-lg,.container-md,.container-sm,.container-xl{max-width:2800px!important}
.card-container{padding-left:15%;padding-right:15%;flex-wrap:wrap;flex-direction:row}
}
@media print {
.menu,.header{display:none}
.page-break{page-break-before:always}
.open-menu .Content{margin-left:0;width:100%}
.Content{position:initial}
table{width:100%}
}
@media (max-width: 1100px) {
:root{--width-menu:3rem;--margin-left-menu:3rem;--margin-left-menu-padding:7rem;--width-menu-icon-selected:1rem;--padding-menu:0 7px;--padding-menu-icon:.5rem 8px .5rem 8px;--padding-menu-icon-select:.3rem 8px .3rem 8px}
.contact > div.no-tablet,.no-tablet{display:none}
.contact > div{flex-direction:column}
}
@media (max-width: 950px) {
  .menu ul li, .menu ul li > div, .menu ul li > div > div, .menu ul li.active > div{padding: 0px;margin:0px;}
  .menu ul li > div img, .contact{display: none;}
  .menu ul li{height: auto;}
:root{--margin-left-menu:0;--margin-left-menu-padding:2rem;--padding-lateral:1rem;--header-height:60px}
.open-menu .menu{bottom:0;width:100%;height:3rem;top:auto;padding-bottom:0;overflow:hidden;box-shadow:0 0 4px rgba(0,0,0,0.4)}
.menu ul li > div > div.submenu{display:none}
.menu ul{display: flex;overflow: auto;-webkit-justify-content: space-around;justify-content: space-around;padding: 0px;align-items: center;min-height: 3rem;}
.Content{padding:1rem;height:calc(100vh - 130px)}
.content-flex.space-between-start.content-flex-two{flex-direction:column}
.content-flex-two > div{flex:0 1 100%;width:100%}
.header{width:calc(100% - 2rem);box-shadow:0 2px 4px rgba(0,0,0,0.15)}
.card-home{flex:0 1 280px;min-width:280px;margin-right:1rem}
.mobile-scroll{overflow:auto;flex-flow:nowrap;margin-right:-1rem}
.filter{min-width:calc(100% - 2rem)}
.content-reviews{padding:0;border:none}
.content-review .flex1 img{width:50px}
.content-review .flex1{flex-wrap:wrap;padding-right:1rem}
.flex1 .column{padding:0}
.content-img{text-align:center;width:100%}
.home .content-reviews{padding:1rem 0}
.content-review .comment{font-size:1rem;line-height:1.5rem}
.w8R{width:5rem}
.content-tabs ul li{padding:0 1rem;flex:0 1 20%}
.menu ul li.active > div{background:transparent;width:auto}
.text-disabled{width:calc(100% - 3rem);margin-left:0}
.select-flow {grid-template-columns: 1fr 1fr;}
.crate-wa > div.content-flex.space-between{
  flex-direction: column;
}
.crate-wa > div > .content-config{
  width: calc(100% - 20px);
}
}
@media only screen and (max-width: 680px) {
.select-flow {grid-template-columns: 1fr;}
.content-flex .content-widget{flex:1 1 100%;margin-bottom:2rem}
.menu ul li .submenu:before,.menu ul li.active .submenu:before{opacity:1}
.orden .content-widget:nth-child(1){margin-right:0}
.card-container{padding-left:15%;max-width:100%;flex-wrap:wrap}
.menu ul li > div > div{display:none}
.no-mobile{display:none}
.menu ul li > div img{display: block;}
.menu ul li.sub > div > div:not(.submenu){display: block; color: rgba(13,33,63,0.9);width: 13px;overflow: hidden;}
.menu ul li.sub > div > div::first-letter {
  font-size: 1.2rem;
  color: #bbb;  
  font-weight: bold; 
}
.flex-direction-column-mobile{flex-direction:column}
.content-review .flex1{flex:0 1 100%;flex-direction:row;flex-wrap:nowrap;border-right:none;border-bottom:1px solid #DADADA;padding-bottom:1rem;margin-bottom:1rem}
.content-img{width:auto}
.content-review .flex1 img{height:80px;width:auto;margin-right:1rem;border-radius:.5rem}
.content-flex.space-between-center.buttons.content-image-scroll{flex-direction:column;align-items:flex-start}
.content-flex.space-between-center.buttons.content-image-scroll > .content-flex.image-scroll{flex-direction:column}
.template-whatsapp{width:100%}
.text-disabled{width:calc(100% - 3rem);margin-left:0}
input[type=range]{width:calc(100% - 3rem)}
hr.internal{width:100%}
.card-home{flex:0 1 100%;min-width:auto;width:calc(100% - 3rem);margin:0 0 1rem}
.content-table .title,.content-reviews .title,.content-config .title{font-size:18px}
.content-tabs ul li{flex:0 1 25%}
.number{width:2rem}
.card-home-bottom .percentage,.card-home .percentage{font-size:24px;line-height:25px}
.spacing{height:1rem}
}
@media (max-width: 500px) {
.content-flex.flex-end.buttons{flex-wrap:wrap}
.content-flex.flex-end.buttons .second{margin-bottom:1rem;width:115px}
.content-tabs ul li{padding:0 5px;font-size:11px}
.content-tabs{overflow:auto}
}
.no-visible{height:0;overflow:hidden;padding:0;border:none;margin:0}
.lds-ellipsis{display:inline-block;position:relative;width:50px;height:20px}
.lds-ellipsis div{position:absolute;top:6px;width:8px;height:8px;border-radius:50%;background:var(--color-primary);animation-timing-function:cubic-bezier(0,1,1,0)}
.lds-ellipsis div:nth-child(1){left:8px;animation:lds-ellipsis1 .6s infinite}
.lds-ellipsis div:nth-child(2){left:8px;animation:lds-ellipsis2 .6s infinite}
.lds-ellipsis div:nth-child(3){left:32px;animation:lds-ellipsis2 .6s infinite}
.lds-ellipsis div:nth-child(4){left:56px;animation:lds-ellipsis3 .6s infinite}
@keyframes lds-ellipsis1 {
0%{transform:scale(0)}
100%{transform:scale(1)}
}
@keyframes lds-ellipsis3 {
0%{transform:scale(1)}
100%{transform:scale(0)}
}
@keyframes lds-ellipsis2 {
0%{transform:translate(0,0)}
100%{transform:translate(24px,0)}
}
.problem-list{list-style:decimal;padding-left:32px}
.problem-list li{line-height:32px}
.problem-list li img{width:24px}
.title-update-plan{font-weight:700;margin:2rem 0 1rem}
.update-plan-IA{padding:1rem;line-height:1.6rem}
.update-plan-IA p{margin:8px 0}
.help{font-style:italic;opacity:.8;padding-top:2rem;display:block}
.update-plan-IA img{width:100%;max-width:20rem}
.sR{text-transform:uppercase}
.search,.sR{text-align:center;background:#0D213F;padding:1rem;border-radius:20px 2px;color:#fff}
.sR.message-install{text-align:left;padding:2rem 0 2rem 1rem}
.modal-social-popup h2{margin:.5rem 0;font-size:1.2rem;display:flex;align-items:center}
.bodySocial{overflow:auto;border-radius:5px}
.modal-body input[type=range]{width:80%}
.bodySocial #contenedor{width:1080px;height:1080px;display:flex;background:#D58A6D;zoom:.65}
.bodySocial .content{width:880px;height:520px;margin:240px 60px;padding:40px;border-radius:25px;align-items:center;display:flex;-webkit-box-shadow:-9px 10px 29px -10px rgba(106,106,106,1);-moz-box-shadow:-9px 10px 29px -10px rgba(106,106,106,1);box-shadow:-9px 10px 29px -10px rgba(106,106,106,1)}
.bodySocial .message{line-height:25px;margin-top:1rem}
.bodySocial [data-starlist]{text-align:left;font-style:normal;display:inline-block;position:relative;unicode-bidi:bidi-override}
.bodySocial [data-starlist]::before{display:block;content:'☆☆☆☆☆'}
.bodySocial [data-starlist]::after{white-space:nowrap;position:absolute;top:0;left:0;content:'★★★★★';width:0;overflow:hidden;height:100%}
.bodySocial [data-starlist^="0.1"]::after,[data-starlist^=".1"]::after{width:2%}
.bodySocial [data-starlist^="0.2"]::after,[data-starlist^=".2"]::after{width:4%}
.bodySocial [data-starlist^="0.3"]::after,[data-starlist^=".3"]::after{width:6%}
.bodySocial [data-starlist^="0.4"]::after,[data-starlist^=".4"]::after{width:8%}
.bodySocial [data-starlist^="0.5"]::after,[data-starlist^=".5"]::after{width:10%}
.bodySocial [data-starlist^="0.6"]::after,[data-starlist^=".6"]::after{width:12%}
.bodySocial [data-starlist^="0.7"]::after,[data-starlist^=".7"]::after{width:14%}
.bodySocial [data-starlist^="0.8"]::after,[data-starlist^=".8"]::after{width:16%}
.bodySocial [data-starlist^="0.9"]::after,[data-starlist^=".9"]::after{width:18%}
.bodySocial [data-starlist^="1"]::after{width:20%}
.bodySocial [data-starlist^="1.1"]::after{width:22%}
.bodySocial [data-starlist^="1.2"]::after{width:24%}
.bodySocial [data-starlist^="1.3"]::after{width:26%}
.bodySocial [data-starlist^="1.4"]::after{width:28%}
.bodySocial [data-starlist^="1.5"]::after{width:30%}
.bodySocial [data-starlist^="1.6"]::after{width:32%}
.bodySocial [data-starlist^="1.7"]::after{width:34%}
.bodySocial [data-starlist^="1.8"]::after{width:36%}
.bodySocial [data-starlist^="1.9"]::after{width:38%}
.bodySocial [data-starlist^="2"]::after{width:40%}
.bodySocial [data-starlist^="2.1"]::after{width:42%}
.bodySocial [data-starlist^="2.2"]::after{width:44%}
.bodySocial [data-starlist^="2.3"]::after{width:46%}
.bodySocial [data-starlist^="2.4"]::after{width:48%}
.bodySocial [data-starlist^="2.5"]::after{width:50%}
.bodySocial [data-starlist^="2.6"]::after{width:52%}
.bodySocial [data-starlist^="2.7"]::after{width:54%}
.bodySocial [data-starlist^="2.8"]::after{width:56%}
.bodySocial [data-starlist^="2.9"]::after{width:58%}
.bodySocial [data-starlist^="3"]::after{width:60%}
.bodySocial [data-starlist^="3.1"]::after{width:62%}
.bodySocial [data-starlist^="3.2"]::after{width:64%}
.bodySocial [data-starlist^="3.3"]::after{width:66%}
.bodySocial [data-starlist^="3.4"]::after{width:68%}
.bodySocial [data-starlist^="3.5"]::after{width:70%}
.bodySocial [data-starlist^="3.6"]::after{width:72%}
.bodySocial [data-starlist^="3.7"]::after{width:74%}
.bodySocial [data-starlist^="3.8"]::after{width:76%}
.bodySocial [data-starlist^="3.9"]::after{width:78%}
.bodySocial [data-starlist^="4"]::after{width:80%}
.bodySocial [data-starlist^="4.1"]::after{width:82%}
.bodySocial [data-starlist^="4.2"]::after{width:84%}
.bodySocial [data-starlist^="4.3"]::after{width:86%}
.bodySocial [data-starlist^="4.4"]::after{width:88%}
.bodySocial [data-starlist^="4.5"]::after{width:90%}
.bodySocial [data-starlist^="4.6"]::after{width:92%}
.bodySocial [data-starlist^="4.7"]::after{width:94%}
.bodySocial [data-starlist^="4.8"]::after{width:96%}
.bodySocial [data-starlist^="4.9"]::after{width:98%}
.bodySocial [data-starlist^="5"]::after{width:100%}
.bodySocial .content img{max-height:calc(520px - 0rem);border-radius:25px;margin-right:40px;max-width:400px}
.saveSocial{margin-left:2rem}

.grid-flow-row-new{
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media (max-width: 1000px) {
  .grid-flow-row-new{
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 800px) {
  .grid-flow-row-new{
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .grid-flow-row-new{
    grid-template-columns: 1fr;
  }
}